/* eslint-disable no-undef */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Paper, Box, Typography, Button, FormControlLabel, Checkbox, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import './Accounts.scss';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { cancelRepackRequestCustomer, repackParcel, rescanParcel, getBuyMeRequestsByStatuses, addBuyMeRequest, consolidateParcels, listWarehouse, getTopNHotStore, copyToClipboard1, getCouponList, get_params, getShippingRequestList, downloadByUrl, downloadParcelImage, downloadScanImage, getRepackRequest, getETAStatus } from '../../reducers/requestHandler';
import { setSelectedShipping, setShipping_list, setIsShippingLoading } from '../../reducers/user-reducers/UserSlicer';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import Loader from '../../style-guide/Loader';
import { URL, autoCloseSnackbar, weightAndVal } from '../../constants/global-constants';
import { Dialog, Snackbar, MultilineField } from '../../style-guide';
import { TextField, SelectField } from '../../style-guide';
import contactTitle from '../../images/title/contact-title.jpg'
import account_banner from '../../images/homepage/account_banner.png'
import flag_tr_small from '../../images/homepage/flag_tr_small.png'
import flag_po_small from '../../images/homepage/flag_po_small.png'
import flag_eu_small from '../../images/homepage/flag_eu_small.png'
import flag_us_small from '../../images/homepage/flag_us_small.png'
import prevArrow from '../../images/homepage/prev-arrow.png'
import nextArrow from '../../images/homepage/next-arrow.png'
import LocMarker from '../../images/homepage/pin.png';
import coupon_bg from '../../images/account/coupon_bg.png'
import woman_jumping from '../../images/account/woman_jumping.png'
import address from '../../images/account/address.png'
import trophy from '../../images/account/trophy.png'
import cashback from '../../images/account/cashback.png'
import correct from '../../images/account/correct.png'
import correct_dis from '../../images/account/correct_dis.png'
import copy from '../../images/account/copy.png'
import price_tag from '../../images/account/price_tag.png'
import packagePng from '../../images/account/package.png'
import info_24 from '../../images/account/info-24.png'
import info_26 from '../../images/account/info-26.png'
import package_recieved from '../../images/account/package_recieved.png'
import payment_pending from '../../images/account/payment_pending.png'
import shipment_started from '../../images/account/shipment_started.png'
import delivery_started from '../../images/account/delivery_started.png'
import target from '../../images/account/target.png'
import location from '../../images/account/location.png'
import media_markt from '../../images/account/media_markt.png'
import sephora from '../../images/account/sephora.png'
import { clearSessionData } from '../../reducers/user-reducers/UserSlicer';
import woman_bg from '../../images/account/woman_bg.png'
import woman_with_money from '../../images/account/woman_with_money.png'
import chrome_bg from '../../images/account/chrome_bg.png'
import amazon from '../../images/account/amazon.png'
import googleChromeIcon from '../../images/account/google-chrome-icon.png'
import { FaWindowClose, FaAngleUp, FaCamera } from "react-icons/fa";
import CloseIcon from '@mui/icons-material/Close';
import ActionButton from '../Common/ActionButton';
import DialogWithSideBar from '../../style-guide/Dialog/DialogWithSideBar';

import store_middle_button from '../../images/store/store_middle_buton_img.png'
import top_back from '../../images/top_back.png'
import MetaTags from 'react-meta-tags';

import {  FaCheck, FaWifi, FaAngleDown } from "react-icons/fa";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import file from '../../images/file.png';
const currencies = [/*{ id: 1, name: "$ דולר", symbol: "$" },*/ { id: 2, name: "€ אירו", symbol: "€" }/*, { id: 3, name: "£ פאונד", symbol: "£" }, { id: 4, name: "₺ לירה טורקית", symbol: "₺" }*/];

export const Accounts = (props) => {
    const { isBuyMePayment, setIsPersonal, setActiveScreenInreg, setIsRegister, setIsLogin, setIsAddAddress, setIsProfile, setIsBuyMePayment } = props;
    const { userInfo, innerContent, shipping_list_count, shipping_list, isShippingLoading } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const { couponList, setCouponList } = props;
    // const  = {props}
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const [storeList, setStoreList] = new useState([]);
    // const [couponList, setCouponList] = new useState([]);
    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [parcelImage, setParcelImage] = new useState("");
    const [isEnable, setEnabled] = new useState(true);
    const [isConsolicationCheckboxEnable, setConsolicationCheckboxEnabled] = new useState(false);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [isHistory, setIsHistory] = new useState(isMobile());
    const [tabIndex, setTabIndex] = new useState(1);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isDialog, setIsDialog] = useState(false);
    const [selectedStore, setSelectedStore] = new useState({});
    const [bSideWindow, setBSideWindow] = new useState(false);
    const [allWh, setAllWh] = new useState([]);
    const [serviceOptions, setServiceOptions] = new useState([{ id: 0, name: "" }, { id: 1, name: "צמצום נפח" }, { id: 2, name: "סריקת חבילה" }]);
    const [serviceOption, setServiceOption] = new useState(0);
    const [serviceParcel, setServiceParcel] = new useState({});
    const [freeRepack, setFreeRepack] = new useState(true);
    const [fiveRepack, setFiveRepack] = new useState(false);
    const [repackFreeText, setRepackFreeText] = new useState("");
    const setFreeRepack1 = (data) => {
        setFreeRepack(data);
        setFiveRepack(!data);
    }
    const onSelectService = (val, serviceParcel) => {
        setServiceParcel(serviceParcel);
        if (val == 1) {
            openRepackageDlg(serviceParcel)
        } else if (val == 2) {
            openRescanDlg(serviceParcel)
        }
    }
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = (add, ind) => {
        setOpenOrderModal(true);
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);

    const [openOrderCCDialog, setOpenOrderCCDialog] = useState(false);
    const onHandleOpenCCDialog = (add, ind) => {
        setOpenOrderCCDialog(true);
    };
    const onHandleCancelCCDialog = () => setOpenOrderCCDialog(false);

    const getStatusById = (sh, id) => {
        for (var i = 0; i < sh?.params?.statusLog?.length; i++) {
            if (sh?.params?.statusLog[i].id == id) {
                return sh?.params?.statusLog[i].DATE;
            }
        }
        return false;
    }
    const getStatusByLogId = (sh) => {
        return !(getStatusById(sh, 5) || getStatusById(sh, 6) || getStatusById(sh, 7));
    }
    const [openDelayedDelivery, setOpenDelayedDelivery] = useState(false);
    const onHandleCloseDelayedDelivery = () => {
        setOpenDelayedDelivery(false);
    }

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [shipmentInfo, setShipmentInfo] = useState({});
    const onHandleOpenInfoModal = (data) => {
        setShipmentInfo(data)
        console.log(data)
        setOpenInfoModal(true);
    };
    const onHandleCancelInfoModal = () => { setOpenInfoModal(false); setParcelImage(""); }


    const [openRepackCostAcceptModal, setOpenRepackCostAcceptModal] = useState(false);
    const [openRepackConfirm, setOpenRepackConfirm] = useState(false);
    const [selectedRepackGroup, setSelectedRepackGroup] = useState(false);
    const [repackData, setRepackData] = useState(0);
    const [repackParcels, setRepackParcels] = useState([]);
    const [repackParcelsGroup, setRepackParcelsGroup] = useState([]);
    const onHandleOpenRepackModal = () => {
        var t_Arr = [];
        var whs = [];
        for (var i = 0; i < shipping_list.length; i++) {
            if ((shipping_list[i].shippingStatus == 2 || shipping_list[i].shippingStatus == 1) && (shipping_list[i].repackStatus == null || shipping_list[i].repackStatus == 0) && !shipping_list[i].status) {
                if (getCountOfCParcels(shipping_list[i]) > 1) {
                    t_Arr.push(shipping_list[i]);
                    whs.push(shipping_list[i].warehouseId);
                }
            }
        }
        whs = whs.filter((item, index) => whs.indexOf(item) === index);
        var getP = function (wid) {
            var parcels = [];
            for (var i = 0; i < t_Arr.length; i++) {
                if (t_Arr[i].warehouseId == wid) {
                    var x = JSON.parse(JSON.stringify(t_Arr[i]))
                    x.selected = false;
                    parcels.push(x);
                }
            }
            return parcels
        }
        var parcelsGroups = []
        for (var i = 0; i < whs.length; i++) {
            var parcels = getP(whs[i]);
            if (parcels?.length > 1) {
                parcelsGroups.push({
                    group: whs[i],
                    parcels: parcels,
                    selected: false,
                    selectedCheckbox: false,
                    freeText: ""
                });
            }
        }
        setRepackParcelsGroup(parcelsGroups);
        setRepackageDlg(true);
    }
    const onRepackCostAcceptModal = (data) => {
        setRepackageShipment(data);
        getRepackRequest(session, data.id + "", function (data) {
            if (data.message == "Not Active Session") {
                // login again
            }
            else if (data.message == "FAIL") {
                // fail message
            }
            else if (data.message == "SUCCESS" && data?.data) {
                setRepackData(data.data);
                setOpenRepackCostAcceptModal(true);
            }
            else {
                // generic error message
            }
        })
    }

    const onHandleOpenRepackConfirm = (data) => {
        setSelectedRepackGroup(data)
        setOpenRepackCostAcceptModal(true);
    };
    const isValidForRepack = (data) => {
        var bValid = false;
        var count = 0;
        for (var i = 0; i < data?.parcels?.length; i++) {
            if (data?.parcels[i].selected) {
                count++;
            }
        }
        //if (count > 1 && data.selectedCheckbox) {
        if (count > 0) {
            bValid = true;
        }
        return bValid
    }
    const getTotalRepackCost = (data) => {
        var total = 0;
        Object.keys(data).map(key => { total += Number(data[key]) })
        return total;
    }
    const onRepackCostAcceptModelClose = () => {
        setOpenRepackCostAcceptModal(false);
    }

    const [repackCount, setRepackCount] = useState(0);
    const [repackIndex, setRepackIndex] = useState(0);
    const acceptRepackCost = () => {
        //dispatch(setIsShippingLoading(true));
        setRepackIndex(0);
        var count = 0;
        for (var i = 0; i < selectedRepackGroup.parcels.length; i++) {
            if (selectedRepackGroup.parcels[i].selected) { count++ }
        }
        setRepackCount(count);
        var index = 0
        for (var i = 0; i < selectedRepackGroup.parcels.length; i++) {
            if (selectedRepackGroup.parcels[i].selected) {
                repackParcel(session, selectedRepackGroup.parcels[i].id, selectedRepackGroup.freeText, function (data) {
                    index++
                    if (index == repackCount) {
                        setRepackageDlg(false);
                        setOpenRepackCostAcceptModal(false);
                        loadParcels();
                    }
                })
                
            }
        }
    }
    const declineRepackCost = () => {
        cancelRepackRequestCustomer(session, repackData.id, function (data) {
            loadParcels();
            setRepackageShipment(null);
            setOpenRepackCostAcceptModal(false);
        })
    }
    const [openConsolidateConfirm, setOpenConsolidateConfirm] = useState(false);
    const [selectedConsGroup, setSelectedConsGroup] = useState(false);
    const isValidForConsolidation= (data) => {
        var bValid = false;
        var count = 0;
        for (var i = 0; i < data?.parcels?.length; i++) {
            if (data?.parcels[i].selected) {
                count++;
            }
        }
        //if (count > 1 && data.selectedCheckbox) {
        if (count > 1) {
            if (count > 1) {
                bValid = true;
            }
        }
        return bValid
    }
    const onHandleOpenConsolidateConfirm = (data) => {
        setSelectedConsGroup(data)
        setOpenConsolidateConfirm(true);
    };
    const onHandleCancelConsolidateConfirm = () => setOpenConsolidateConfirm(false);
    const getCountOfCParcels = (data) => {
        var count = 0;
        for (var i = 0; i < shipping_list.length; i++) {
            if (shipping_list[i].warehouseId == data.warehouseId) {
                count++
            }
        }
        return count;
    }
    const [expanded, setExpanded] = useState('');
    const handleChange = (panel) => (isExpanded) => {
        if (expanded === panel) {
            setExpanded(false);
        }
        else {
            setExpanded(isExpanded ? panel : false);
        }

    };
    
    function formatDate(date) {
        let p = new Intl.DateTimeFormat('en-GB', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            hour12: false,
            timeZone: 'UTC',
            // timeZoneName: "longOffset",
        }).format(date)
        return p;
    }
    const [expContent, setExpContent] = useState(false);
    const [consolidationParcelsGroup, setConsolidationParcelsGroup] = useState(false);
    const [openConsolidateModal, setOpenConsolidateModal] = useState(false);   
    const onHandleOpenConsolidateModal = (data) => {
        setOpenConsolidateModal(true);
        var t_Arr = [];
        var whs = [];
        for (var i = 0; i < shipping_list.length; i++) {
            if ((shipping_list[i].shippingStatus == 2 || shipping_list[i].shippingStatus == 1) && shipping_list[i].consolidationStatus != 3 && !shipping_list[i].status && !shipping_list[i].repackStatus) {
                if (getCountOfCParcels(shipping_list[i]) > 1) {
                    t_Arr.push(shipping_list[i]);
                    whs.push(shipping_list[i].warehouseId);
                }
            }
        }
        whs = whs.filter((item, index) => whs.indexOf(item) === index);
        var getP = function (wid) {
            var parcels = [];
            for (var i = 0; i < t_Arr.length; i++) {
                if (t_Arr[i].warehouseId == wid) {
                    var x = JSON.parse(JSON.stringify(t_Arr[i]))
                    x.selected = false;
                    parcels.push(x);
                }
            }
            return parcels
        }
        var parcelsGroups = []
        for (var i = 0; i < whs.length; i++) {
            var parcels = getP(whs[i]);
            if (parcels?.length>1) {
                parcelsGroups.push({
                    group: whs[i],
                    parcels: parcels,
                    selected: false,
                    selectedCheckbox: true,
                    freeText: ""
                });
            }
        }
        setConsolidationParcelsGroup(parcelsGroups)
    };
    const onHandleCancelConsolidateModal = () => setOpenConsolidateModal(false);

    const [showExtraServices, setShowExtraServices1] = useState(-1);
    const setShowExtraServices = (event, data) => {
        event.stopPropagation();
        setShowExtraServices1(data);
    }
    const setShowExtraServicesHide = (data) => {
        setShowExtraServices1(data);
    }

    const [openBuyMeModal, setOpenBuyMeModal] = useState(false);
    const [numberOfUnitId, setnumberOfUnitId] = useState(1);
    const [selectedCur, setSelectedCur] = useState(2);
    const [repackService, setRepackService] = useState(1);
    const [numberOfUnits, setNumberOfUnits] = useState([{id:1,name:"1"},{id:2,name:"2"},{id:3,name:"3"},{id:4,name:"4"},{id:5,name:"5"},{id:6,name:"6"},{id:7,name:"7"},{id:8,name:"8"},{id:9,name:"9"},{id:10,name:"10"},{id:11,name:"11"},{id:12,name:"12"},{id:13,name:"13"},{id:14,name:"14"},{id:15,name:"15"}]);
    const [buyMeShops, setBuyMeShops] = useState([]);
    const [selectedBuyMeShopId, setSelectedBuyMeShopId] = useState({});
    const [buyMeInfo, setBuyMeInfo] = useState({});
    const onHandleOpenBuyMeModal = (data) => {
        setOpenBuyMeModal(true);
    };
    const onHandleCancelBuyMeModal = () => setOpenBuyMeModal(false);

    const [buyMeRequestsItems, setBuyMeRequestsItems] = useState([]);
    const getBuyMeRequestsItems = function () {
        var statuses = [1, 2, 3]
        dispatch(setIsShippingLoading(true))
        getBuyMeRequestsByStatuses(userInfo.session, { statuses: statuses }, function (resp) {
            console.log(resp)
            setBuyMeRequestsItems(resp?.data)
            dispatch(setIsShippingLoading(false))
        })
    }
    const setNewShippingList = (data) => {
        var index = 0;
        var new_shipping_list = [];
        for (var i = 0; i < shipping_list_count; i++) {
            if (shipping_list[i].id == data.id) {
                new_shipping_list.push(JSON.parse(JASON.stringify(data)));
            }
            else {
                new_shipping_list.push(JSON.parse(JASON.stringify(shipping_list[i])));
            }
        }
        dispatch(setShipping_list(nnew_shipping_list));
    }
    const getChargeableWeight1 = function () {

        if (!shipmentInfo?.params?.userPaymentData?.weight || !shipmentInfo?.params?.userPaymentData?.length || !shipmentInfo?.params?.userPaymentData?.width || !shipmentInfo?.params?.userPaymentData?.height) {
            return ""
        }
        return Number(shipmentInfo?.params?.userPaymentData?.length) * Number(shipmentInfo?.params?.userPaymentData?.width) * Number(shipmentInfo?.params?.userPaymentData?.height) / 5000
    }
    const getChargeableWeight = function () {

        if (!shipmentInfo?.params?.userPaymentData?.weight || !shipmentInfo?.params?.userPaymentData?.length || !shipmentInfo?.params?.userPaymentData?.width || !shipmentInfo?.params?.userPaymentData?.height) {
            return ""
        }
        var twt = 0;
        var chWt = Number(shipmentInfo?.params?.userPaymentData?.length) * Number(shipmentInfo?.params?.userPaymentData?.width) * Number(shipmentInfo?.params?.userPaymentData?.height) / 5000

        if (Number(shipmentInfo?.params?.userPaymentData?.weight) > chWt) {
            twt = shipmentInfo?.params?.userPaymentData?.weight;
        } else {
            twt = chWt;
        }
        if (shipmentInfo?.params?.calculatedBasePrice) {
            if (Number(shipmentInfo?.params?.calculatedBasePrice))
                twt = Math.min(twt, Number(shipmentInfo?.params?.calculatedBasePrice));
        }
        return twt
    }

    const goTo = (e) => {
    }
    const getDateFormat = (e) => {
        var date = new Date(e);
        var dat = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
        var month = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)));
        var date_formated = dat + '/' + month + '/' + date.getFullYear();
        return date_formated
    }

    const getStatusDescId = (sh) => {
        var id = getStatusId(sh);
        var curLable = false;
        if (id) {
            switch (Number(id)) {
                case 4:
                    curLable = "בדרך לארץ"; // "בתהליך מכס"
                    break;
                case 5:
                    curLable = "נמסר להפצה"
                    break;
                case 6:
                    curLable = "נמסר להפצה"
                    break;
                case 7:
                    curLable = "משלוח נמסר";
                    break;
                default:
                    curLable = false;
            }
        }
        return curLable
    }
    const getStatusId = (sh) => {
        for (var i = 0; i < sh?.params?.statusLog?.length; i++) {
            if (sh?.params?.statusLog[i].DATE) {
                return sh?.params?.statusLog[i].id
            }
        }
        return false;
    }
    const getStatus2Id = (sh) => {
        for (var i = 0; i < sh?.params?.statusLog?.length; i++) {
            if (sh?.params?.statusLog[i].id==2) {
                return sh?.params?.statusLog[i]
            }
        }
        return null;
    }

    const repackCompleted = (sh) => {
        if (sh.repackStatus == 104) {
            var total = 0;
            Object.keys(sh.params.repackCosts).map(key => { total += ((key != undefined && key != "undefined") ? Number(sh.params.repackCosts[key]) : 0) })
            if (total > 0) {
                return true;
            }
        }
        return false;
    }

    const rescanCompleted = (sh) => {
        if (sh.rescanStatus == 104) {
            var total = 0;
            if (sh.params.rescanCost)
            /*{
                var keys = Object.keys(sh.params.rescanCost);
                for (var i = 0; i < keys.length; i++) {
                    if (sh.params.rescanCost[keys[i] + ""])
                }
            }*/
                Object.keys(sh.params.rescanCost)?.map(key => { total += ((key != undefined && key != "undefined") ? Number(sh.params.rescanCost[key+""]) : 0) })
            if (total > 0) {
                return true;
            }
        }
        return false;
    }


    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            //
        }
    }
    const loadParcels = (name) => {
        dispatch(setIsShippingLoading(true));
        var statuses = [1, 2, 3, 4, 5, 6, 601, 41, 104, 100, 201]
        getShippingRequestList(getCookie("SESSION_ID"), statuses, function (response) {
            if (response?.misc?.CODE === 1) {
                response.data.sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                });
                dispatch(setShipping_list(response.data))
                dispatch(setIsShippingLoading(false))
                

            } else {
                setIsLogin(true)
                navigate("/home")
                setCookie("SESSION_ID", "", 30);
                dispatch(clearSessionData({
                    user: {
                        firstName: ""
                    }
                }))

            }
        })
    }
    const getTransportValue = (twt, weightAndVal, totalVol) => {
        var minWeight = 0;
        var maxWeight = 0;
        var minVal = 0;
        try {
            minWeight = maxWeight = weightAndVal[0].wt
            weightAndVal.forEach((weightVal) => {
                var x = weightVal.wt;
                if (x > maxWeight) {
                    maxWeight = x;
                }
                if (x < minWeight) {
                    minWeight = x;
                }
            })
        } catch (e) {
            minWeight = 0.25;
            maxWeight = 26.9;
        }
        twt = Number(twt) <= minWeight ? minWeight : (Number(twt) > maxWeight ? maxWeight : Number(twt));
        //twt = Number(Number(twt).toFixed(1))
        var transportVal = 0;
        for (var j = 0; j < weightAndVal.length; j++) {
            var weightVal = weightAndVal[j];
            if (weightVal.wt >= twt) {
                transportVal = weightVal.val;
                break;
            }
            if (!weightVal.wt && twt == minWeight) {
                transportVal = weightVal.val;
                break;
            }
        }
        return Number(transportVal);
    }
    const getIndividualCost = (parcels, wh) => {
        var totalWt = 0;
        var totalL = 0;
        var totalH = 0;
        var totalW = 0;
        var totalVol = 0;
        var totalCost = 0;
        var ids = [];
        var wVal = handleWarHWts(wh, wh.params?.wtNval.length);
        for (var i = 0; i < parcels.length; i++) {
            if (parcels[i].selected) {
                totalWt = Number(parcels[i].params?.userPaymentData?.weight);
                totalL = Number(parcels[i].params?.userPaymentData?.length);
                totalH = Number(parcels[i].params?.userPaymentData?.height);
                totalW = Number(parcels[i].params?.userPaymentData?.width);
                totalVol = Number(parcels[i].params?.userPaymentData?.length) * Number(parcels[i].params?.userPaymentData?.width) * Number(parcels[i].params?.userPaymentData?.height);

                var chWt = totalVol / 5000 > totalWt ? totalVol / 5000 : totalWt;
                totalCost += getTransportValue(chWt, wVal, totalVol);
            }
        }
        //var totalVol = totalL * totalH * totalW;
        var chWt = totalVol / 5000 > totalWt ? totalVol / 5000 : totalWt;
        return "$ " + totalCost;
    }
    const handleWarHWts = (w, len) => {

        var t = [];
        try {
            var tV = w?.params?.wtNval;
            for (var i = 0; i < len; i++) {
                if (tV[i][0] && tV[i][1]) {
                    t.push({
                        wt: Number(tV[i][0]),
                        val: Number(tV[i][1]),
                    })
                }
            }
        } catch (e) {
            console.log(e)
        }

        // setWtAndVals(t)
        return t;
    }
    const getCombinedCost = (parcels, wh) => {
        var volMult = wh.params?.cVolMult ? wh.params?.cVolMult : 1;
        var totalWt = 0;
        var totalL = 0;
        var totalH = 0;
        var totalW = 0;
        var totalVol = 0;
        var wVal = handleWarHWts(wh, wh.params?.wtNval.length);
        for (var i = 0; i < parcels.length; i++) {
            if (parcels[i].selected) {
                totalWt += Number(parcels[i].params?.userPaymentData?.weight);
                totalL += Number(parcels[i].params?.userPaymentData?.length);
                totalH += Number(parcels[i].params?.userPaymentData?.height);
                totalW += Number(parcels[i].params?.userPaymentData?.width);

                totalVol += volMult * Number(parcels[i].params?.userPaymentData?.length) * Number(parcels[i].params?.userPaymentData?.width) * Number(parcels[i].params?.userPaymentData?.height);
            }
        }
        var chWt = totalVol / 5000 > totalWt ? totalVol / 5000 : totalWt;
        if (wh.params.useBoxes) {
            var boxes = [];
            var bxs = wh.params.boxes.split(",")
            for (var i = 0; i < bxs.length; i++) {
                var bs = bxs[i].split(":");
                boxes.push({ WT: Number(bs[4]), L: Number(bs[1]), W: Number(bs[2]), H: Number(bs[3]), V : Number(bs[1]) * Number(bs[2]) * Number(bs[3]) })
            }
            if (boxes.length == 0) {
                var chWt = totalVol / 5000 > totalWt ? totalVol / 5000 : totalWt;
                return "$ " + getTransportValue(chWt, wVal, totalVol);
                //return "original wt: " + totalWt + ", L: " + totalL + ", W: " + totalW + ", H: " + totalH;
            }
            boxes.sort(function (a, b) {
                return a.V - b.V;
            });
            if (totalVol < boxes[0].V) {
                totalVol = boxes[0].V;
                totalL = boxes[0].L;
                totalW = boxes[0].W;
                totalH = boxes[0].H;
                chWt = totalVol / 5000 > totalWt + boxes[0].WT ? totalVol / 5000 : totalWt + boxes[0].WT;
            } else if (totalVol > boxes[boxes.length - 1].V) {
                chWt = totalVol / 5000 > totalWt ? totalVol / 5000 : totalWt;
            } else {
                for (var i = 0; i < boxes.length-1; i++) {
                    if (totalVol > boxes[i].V && totalVol < boxes[i + 1].V) {
                        totalVol = boxes[i + 1].V;
                        totalL = boxes[i + 1].L;
                        totalW = boxes[i + 1].W;
                        totalH = boxes[i + 1].H;
                        chWt = totalVol / 5000 > totalWt + boxes[i + 1].WT ? totalVol / 5000 : totalWt + boxes[i + 1].WT;
                        break;
                    }
                }
            }
            return "$ " + getTransportValue(chWt, wVal, totalVol);
            //return "wt: " + totalWt + ", vol: " + totalVol + ", chW: " + chWt;
        } else {
            return "$ " + getTransportValue(chWt, wVal, totalVol);
            //return "wt: " + totalWt + ", vol: " + totalVol + ", chW: " + chWt ;
        }
    }
    const dissableConsolidation = (parcel, wh) => {
        if (!wh?.params?.useBoxes)
            return false;
        if (parcel.params?.userPaymentData.weight > wh?.params?.cLimitWt ||
            parcel.params?.userPaymentData.length > wh?.params?.cLimitL ||
            parcel.params?.userPaymentData.height > wh?.params?.cLimitH ||
            parcel.params?.userPaymentData.width > wh?.params?.cLimitW)
            return true;
        return false;
    }
    const getExt = (name) => {
        let ext = ".png";
        if (name) {
            var lastIndexOf = name.lastIndexOf(".");
            if (lastIndexOf == -1) {
                ext = ".png"; // empty extension
            }
            ext = name.substring(lastIndexOf);
        }
        return ext;
    }
    useEffect(() => {
        getBuyMeRequestsItems();
    }, [isBuyMePayment]);
    useEffect(() => {
        if (userInfo.session) {

            
            getCouponList(userInfo.user.token,function (response) {
                if (response.misc.CODE === 1) {
                    setCouponList(response.data)
                }
            })
            getTopNHotStore(userInfo.session, 6, function (response) {
                if (response.misc.CODE === 1) {
                    setStoreList(response.data)
                }
            })
        }
        var shId = get_params("shId");
        if (shId || true) {
            var statuses = [1, 2, 3, 4, 5, 6, 601, 41, 104, 100, 201]
            getShippingRequestList(getCookie("SESSION_ID"), statuses, function (response) {
                if (response?.misc?.CODE === 1) {
                    response.data.sort(function (a, b) {
                        return new Date(b.date) - new Date(a.date);
                    });
                    dispatch(setShipping_list(response.data))
                    dispatch(setIsShippingLoading(false))
                    var sh = shId ? response.data.find(u => u.hawb === shId) : null;
                    if (sh) {
                        dispatch(setSelectedShipping(sh));
                        setIsPersonal(true);
                    }

                } else {
                    setIsLogin(true)
                    navigate("/home")
                    setCookie("SESSION_ID", "", 30);
                    dispatch(clearSessionData({
                        user: {
                            firstName: ""
                        }
                    }))

                }
            })

        }
        listWarehouse(function (data1) {
            let data = []
            let buyMeShops1 = []
            let l = 0;
            data1.data.map((dat, index) => {
                if (index == 0) {
                    l = 0
                }
                else if (index > 0 && dat.params?.banner) {
                    l += dat.params.banner.length;
                }
                dat.params.index = l;
                if (dat.name != 'מערב אירופה' && dat.name != 'טורקיה') {
                    data.push(dat)
                }
                if (dat.name == 'מערב אירופה') {
                    buyMeShops1.push(dat)
                }


            })
            data1.data.map((dat, index) => {
                if (index == 0) {
                    l = 0
                }
                else if (index > 0 && dat.params?.banner) {
                    l += dat.params.banner.length;
                }
                dat.params.index = l;
                if (dat.name === 'מערב אירופה') {
                    data.push(dat)
                }

            })
            data1.data.map((dat, index) => {
                if (index == 0) {
                    l = 0
                }
                else if (index > 0 && dat.params?.banner) {
                    l += dat.params.banner.length;
                }
                dat.params.index = l;
                if (dat.name === 'טורקיה') {
                    data.push(dat)
                }


            })


            setAllWh(data)
            if (buyMeShops1.length) {
                setSelectedBuyMeShopId(buyMeShops1[0].id)
            }
            setBuyMeShops(buyMeShops1)
        });
    }, [userInfo]);

    const getHistoryCount = (st) => {
        var count = 0;
        for (var i = 0; i < shipping_list?.length; i++) {
            if (shipping_list[i].shippingStatus == 6 || shipping_list[i].status) {
                count++
            }
        }
        return count;
    }
    const [isRepackageDlg, setRepackageDlg] = new useState(false);
    const [repackageShipment, setRepackageShipment] = new useState({});
    const openRepackageDlg = (sh) => {
        setRepackageShipment(sh);
        setRepackageDlg(true);
    }
    const closeRepackageDlg = () => {
        setRepackageShipment(null);
        setRepackageDlg(false);
    }
    const [isRescanDlg, setRescanDlg] = new useState(false);
    const [rescanShipment, setRescanShipment] = new useState({});
    const openRescanDlg = (sh) => {
        setRescanShipment(sh);
        setRescanDlg(true);
    }
    const closeRescanDlg = () => {
        setRescanShipment(null);
        setRescanDlg(false);
    }
    const isServiceNotAvailable = (sh) => {
        var status = true;
        if ((sh.shippingStatus <= 2 || sh.shippingStatus > 6) && (sh.repackStatus == null || sh.repackStatus == 0) && (sh.consolidationStatus == null || sh.consolidationStatus == 0 || sh.consolidationStatus == 3)) {
            status = false;
        }
        return status;
    }
    const getDescByShStatus = (st) => {
        switch (st) {
            case 1:
                return "החבילה נקלטה"
            case 104:
                return "החבילה נקלטה"
            case 2:
                return "ממתין לתשלום"
            case 3:
                return "בדרך לארץ"
            case 4:
                return "בדרך לארץ"
            case 41:
                return "בדרך לארץ"
            case 6:
                return "החבילה נמסרה"
            default:
                return "בדרך אליך"
        }
    }
    const copyToClipboard = (text) => {
        copyToClipboard1(text, function (data) {
            dispatch(snackbarToggle({ type: 'success', message: data }));
            window.setTimeout(function () { dispatch(snackbarToggle(false)); }, autoCloseSnackbar);

        })
    }
    
    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} onClick={() => { setShowExtraServicesHide(-1); } }>
            
                {openRepackCostAcceptModal && (
                    <Dialog maxWidth='sm' open={openRepackCostAcceptModal} title={`Accept Cost`} onHandleCancel={onRepackCostAcceptModelClose}>

                        <Grid flexDirection="column" container item justifyContent="center" sx={{ fontSize: "20px" }}>
                            
                            <p>Do you want to accept repack service for selected parcels?</p>
                        </Grid>
                        <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                            acceptRepackCost();
                        }}>Accept</Button>
                        <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                            setRepackageDlg(false);
                            setOpenRepackCostAcceptModal(false);
                        }}>Decline</Button>

                    </Dialog>
                )}
                {false && (
                    <Dialog maxWidth='md' direction={'rtl'} open={isRepackageDlg} title={'Repack Dialog'} onHandleCancel={closeRepackageDlg}>
                        <Grid container item justifyContent="flex-end">
                            <Grid container item justifyContent="flex-end" sx={{ border: "1px solid black", mb: 4, borderRadius: "10px", padding: "5px" }}>
                                {/* <Typography className='dir-rtl'>
                                {`איחוד חבילות מתאפשר לחבילות שעדיין לא בוצע תשלום בגינם ומבוסס על כתובת הקניה `}<br></br>
                                { `
כאשר מאחדים חבילות יש לקחת בחשבון את היבטי המיסוי בארץ. איחוד של מספר חבילות הפטורות ממס בפני עצמן, יכול להביא את סך החשבונית למדרגה המחויבת במס ובמקרה הנ"ל יידרש מהלקוח תשלום מיסים.`}
                                    { `באחריות הלקוח לבדוק את הנושא מראש טרום בקשת האיחוד, אפשר להשתמש`} <a onClick={() => { window.open("https://redboxparcel.com/redboxPricing", "_blank") }}>{`במחשבון `}</a>

                                </Typography>*/}
                                <Grid container item justifyContent="flex-end">
                                    <Typography className='dir-rtl' >
                                        <b>{ `Repack Text here`}</b>  <br></br>
                                        { 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available'}
                                    </Typography>
                                    {expContent && <Typography className='dir-rtl' id="expContent">

                                        <b>{ `Extended Repack Text Here`}</b><br></br>
                                        { `In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available`}<br></br>
                                    </Typography>}
                                </Grid>
                                
                                <Button variant='link' sx={{ borderRadius: "25px", textDecoration: "underline", padding: "5px 25px" }} onClick={() => {
                                    setExpContent(!expContent)

                                }}> {!expContent ? 'מידע נוסף' : 'סגור'}</Button>
                            </Grid>
                            {repackParcelsGroup.map((cParcelGroup, groupIndex) => (<Grid key={cParcelGroup.group} container item justifyContent="center">

                                <Grid container item justifyContent="center" sx={{ border: "1px solid black", borderRadius: "10px", mb: 4 }}>
                                    <Grid container item justifyContent="center" lg={11} >
                                        <Button variant='outlined' sx={{ mt: -2, borderRadius: "5px", borderColor: "#d70007", color: "#d70007", padding: "3px", background: "white !important" }} onClick={() => {


                                        }} >
                                            <b> {allWh?.find(u => u.id === cParcelGroup.group)?.name}</b>
                                        </Button>
                                    </Grid>


                                    <Grid container item justifyContent="space-between" lg={11} flexWrap="nowrap" sx={{ mt: 2 }}>

                                        <Grid container item justifyContent="flex-end" sm={4}><div className="full_width_container bold_text rtl_text smallx_text">מספר מעקב</div> </Grid>
                                        <Grid container item justifyContent="flex-end" sm={2}><div className="full_width_container bold_text rtl_text smallx_text">חנות</div> </Grid>
                                        <Grid container item justifyContent="flex-end" sm={2}><div className="full_width_container bold_text rtl_text smallx_text"> תכולת החבילה   </div></Grid>
                                        <Grid container item justifyContent="flex-end" sm={2} ><div className="full_width_container bold_text rtl_text smallx_text">ספק </div>  </Grid>
                                        <Grid container item justifyContent="flex-end" sm={1.5} style={{ marginTop: "-9px" }} alignContent="flex-start">
                                            {/* < Checkbox checked={cParcelGroup.selected} onChange={event => {
                                                var tCGroup = JSON.parse( JSON.stringify([...consolidationParcelsGroup]))
                                                tCGroup[groupIndex].selected = event.target.checked;
                                                var parcels = JSON.parse(JSON.stringify([...tCGroup[groupIndex].parcels]))
                                                for (var i = 0; i < parcels.length; i++) {
                                                    parcels[i].selected = event.target.checked;
                                                }
                                                tCGroup[groupIndex].parcels = parcels
                                                setConsolidationParcelsGroup(tCGroup)
                                                //event.target.checked
                                            }} />*/}
                                        </Grid>
                                    </Grid>


                                    {cParcelGroup?.parcels?.map((parcel, index) => (<Grid container item justifyContent="space-between" lg={11} flexWrap="nowrap">

                                        <Grid container item justifyContent="flex-end" sm={4}>{parcel.params?.trackingNum} </Grid>
                                        <Grid container item justifyContent="flex-end" sm={2}>{parcel.hawb} </Grid>
                                        <Grid container item justifyContent="flex-end" sm={2}>{parcel.shippingDesc} </Grid>
                                        <Grid container item justifyContent="flex-end" sm={2} ><div style={{ maxWidth: "75%", textAlign: "right" }}>{parcel.params?.sender?.senderName}, {parcel.params?.sender?.senderCountry}</div> </Grid>
                                        <Grid container item justifyContent="flex-end" sm={1.5} style={{ marginTop: "-9px" }} alignContent="flex-start">
                                            <Checkbox checked={parcel.selected} onChange={event => {
                                                var tCGroup = JSON.parse(JSON.stringify([...repackParcelsGroup]))
                                                var parcels = JSON.parse(JSON.stringify([...tCGroup[groupIndex].parcels]))
                                                for (var i = 0; i < parcels.length; i++) {
                                                    if (index == i) {
                                                        parcels[i].selected = event.target.checked;
                                                    }
                                                }
                                                tCGroup[groupIndex].parcels = parcels
                                                setRepackParcelsGroup(tCGroup)
                                                //event.target.checked
                                            }} />
                                        </Grid>
                                    </Grid>))}
                                    <Grid container item justifyContent="flex-end" sx={{ fontSize: "20px", mb: 1, mt: 1 }} lg={11}>
                                        <Grid container item justifyContent="flex-end" sx={{ fontSize: "20px", mb: 1, mt: 1, mr: 1 }} lg={11}>

                                            <MultilineField
                                                className='multilineWidth_70 text-field'
                                                lg={8}
                                                rows={2}
                                                value={cParcelGroup.freeText}
                                                onChange={event => {
                                                    var tCGroup = JSON.parse(JSON.stringify([...repackParcelsGroup]))
                                                    tCGroup[groupIndex].freeText = event.target.value;
                                                    setRepackParcelsGroup(tCGroup);
                                                    //onHandleOpenRepackConfirm(tCGroup)
                                                }}
                                                label='מידע נוסף'
                                                dir={'rtl'}
                                            />
                                        </Grid>
                                        {/*<Grid container item justifyContent="flex-end" sx={{ fontSize: "20px", mb: 0, mt: 0 }} lg={ 12}>
                                            <p style={{ direction: "rtl", fontSize: "1rem", marginBottom: 0 }}>{`איחוד החבילות עלול ליצור חיוב נפחי גדול מסה"כ הנפח של כל חבילה בנפרד משום שחישוב הנפח של החבילה המאוחדת נעשה לפי הפאות הארוכות ביותר.`}</p>

                                            <FormControlLabel style={{ direction: "rtl", marginRight: "-10px" }} control={< Checkbox checked={cParcelGroup?.selectedCheckbox} onChange={event => {

                                                var tCGroup = JSON.parse(JSON.stringify([...consolidationParcelsGroup]))
                                                tCGroup[groupIndex].selectedCheckbox = !tCGroup[groupIndex].selectedCheckbox;
                                                setConsolidationParcelsGroup(tCGroup)

                                                //setConsolicationCheckboxEnabled(!isConsolicationCheckboxEnable);
                                            }} />} label={`אישור`} />

                                        </Grid>*/}
                                        <Grid container item alignItems="center" justifyContent="center" sx={{ fontSize: "20px", mb: 1, mt: 0 }} lg={12}>

                                            <Button disabled={!isValidForRepack(cParcelGroup)} variant='contained' sx={{ borderRadius: "25px", background: "#d70007", padding: "5px 25px" }} onClick={() => {
                                                onHandleOpenRepackConfirm(cParcelGroup)
                                                // onHandleCancelConsolidateModal();
                                            }} >Repack</Button>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>))}

                        </Grid>
                        <Grid container item justifyContent="center" sx={{ fontSize: "20px", mt: 3 }} >
                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {

                                closeRepackageDlg();
                            }} >Close</Button>
                        </Grid>
                    </Dialog>
                )}
                {isRepackageDlg && (
                    <Dialog maxWidth='md' direction={'rtl'} open={isRepackageDlg} title={ `צמצום נפח חבילה`} onHandleCancel={closeRepackageDlg}>
                        <Grid container item justifyContent="flex-end">
                            <Typography className='dir-rtl' >
                                <ul style={{ listStyleType: "number", marginRight: "20px", marginBlockStart: "0", paddingInlineStart: "0", marginBlockEnd: "0" }}>
                                    <li>{`שרות זה מתאפשר לאחר קליטת החבילות במרכזים הלוגיסטיים שלנו, רק עבור חבילות שלא שולמו עדיין.`}</li>
                                    <li>{`בסיום התהליך תשלח הודעה על צמצום הנפח.`}</li>
                                    <li>{`בשל מגבלות משקל וגודל המוצרים, ישנם מקרים בהם צוות המחסן לא יוכל לבצע צמצום נפח במקרה זה תבוטל הבקשה יל ידי הצוות המקצועי של המחסן.`}</li>

                                </ul>
                            </Typography>
                        </Grid>
                        <Grid container direction={'rtl'} item flexDirection="row-reverse" justifyContent="centert" sx={{ padding:"10px", fontSize: "20px", textAlign: "right", marginTop: "20px", marginBottom: "20px" }}>
                            <Grid container direction={'rtl'} item flexDirection="row-reverse" justifyContent="flex-start" sx={{ fontSize: "20px", textAlign: "right" }} flexWrap="nowrap">
                                <input onChange={() => setFreeRepack1(true)} type="radio" value="MALE" name="gender" defaultChecked /> <p style={{ margin: "0", marginRight: "10px" }}>{"."}{`צמצום חבילה המכילה אופנה לשקית שילוח - ללא תוספת תשלום`}</p>
                            </Grid>
                            <Grid container direction={'rtl'} item flexDirection="row-reverse" alignItems="flex-start" justifyContent="flex-start" sx={{ fontSize: "20px", textAlign: "right" }} flexWrap="nowrap">
                                <input style={{marginTop:"10px"}} onChange={() => setFreeRepack1(false)} type="radio" value="FEMALE" name="gender" /> <p style={{ margin: "0", marginRight: "10px" }}>{`.צמצום חבילה לאריזה מקורית (הוצאה מאריזת מגן), שרות זה כרוך בתשלום של 5$`}<br /> { `.בבחירת שירות זה הכיסוי הביטוחי יכסה אובדן בלבד`}</p>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent="flex-end" sx={{ fontSize: "20px", mb: 1, mt: 1, mr: 1 }} lg={12}>

                            <MultilineField
                                className='multilineWidth_70 text-field'
                                lg={8}
                                rows={2}
                                value={repackFreeText}
                                onChange={event => {
                                    setRepackFreeText(event.target.value);
                                }}
                                label= 'הערות לצוות המחסן (באנגלית בלבד)'
                                dir={'rtl'}
                            />
                        </Grid>
                        <Grid container direction={'rtl'} item justifyContent="center" sx={{ fontSize: "20px", textAlign: "right" }}>
                            {"?"} { `האם לצמצם לך את החבילה`}
                        </Grid>
                        <Grid container item justifyContent="center" sx={{ fontSize: "20px" }}>
                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                closeRepackageDlg();
                            }}>{ `לא`}</Button>
                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                repackParcel(getCookie("SESSION_ID"), repackageShipment.id, freeRepack, repackFreeText, function (data) {
                                    if (data?.misc?.CODE == 1 && data?.data) {
                                        //setNewShippingList(data.data);
                                        loadParcels();
                                        closeRepackageDlg();
                                    } else {
                                        closeRepackageDlg();
                                    }
                                })
                            }}>{ ` כן`}</Button>
                        </Grid>

                    </Dialog>
                )}
                {isRescanDlg && (
                    <Dialog maxWidth='lg' direction={'rtl'} open={isRescanDlg} title={ `צילום תכולת חבילה`} onHandleCancel={closeRescanDlg}>

                        <Grid container direction={'rtl'} item justifyContent="flex-end" sx={{ padding:"10px", fontSize: "20px", textAlign: "right" }}>
                            <Typography className='dir-rtl' >
                                <ul style={{ listStyleType: "number", marginRight: "20px", marginBlockStart: "0", paddingInlineStart: "0", marginBlockEnd: "0" }}>
                                    <li>{`שרות זה מתאפשר לאחר קליטת החבילות במרכזים הלוגיסטיים שלנו, רק עבור חבילות שלא שולמו עדיין`}{"."}</li>
                                    <li>{`בסיום התהליך יצורף צילום התכולה לפרטי החבילה באזור האישי שלך באתר`}{"."}</li>
                                    <li>{`השירות כרוך בתשלום של 4$ `}{"."}</li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid container direction={'rtl'} item justifyContent="center" sx={{ fontSize: "20px", textAlign: "right", marginTop:"30px" }}>
                            ? { `האם לצלם לך את תכולת החבילה`}
                        </Grid>
                        <Grid container item justifyContent="center" sx={{ fontSize: "20px" }}>
                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                closeRescanDlg();
                            }}>{ `לא`}</Button>
                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                rescanParcel(getCookie("SESSION_ID"), rescanShipment.id, "", function (data) {
                                    if (data?.misc?.CODE == 1 && data?.data) {
                                        //setNewShippingList(data.data);
                                        loadParcels();
                                        closeRescanDlg();
                                    } else {
                                        closeRescanDlg();
                                    }
                                })
                            }}>{ `כן`}</Button>
                        </Grid>

                    </Dialog>
                )}
                {openOrderCCDialog && (
                    <Dialog maxWidth='lg' direction={'rtl'} open={openOrderCCDialog} title={`פרטים`} onHandleCancel={onHandleCancelCCDialog}>

                        <Grid container item justifyContent="center" sx={{ fontSize: "20px" }}>
                            { `.צור קשר עם שרות לקוחות`}
                        </Grid>
                        <Grid container item justifyContent="center" sx={{ fontSize: "20px" }}>
                        <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {
                            onHandleCancelCCDialog();
                            }}>OK</Button>
                        </Grid>

                    </Dialog>
                )}
                {openOrderModal && (
                    <Dialog maxWidth='sm' open={openOrderModal} title={`Add Profile`} onHandleCancel={onHandleCancelModal}>

                        <Grid container item justifyContent="center" sx={{ fontSize: "20px" }}>
                            נא לעדכן תעודת זהות של בעל החשבון
                        </Grid>
                        <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {
                            setIsProfile(true)
                            onHandleCancelModal();
                        }}>OK</Button>

                    </Dialog>
                )}
                {openDelayedDelivery && (
                    <Dialog maxWidth='sm' open={openDelayedDelivery} title={`זמני מסירה`} onHandleCancel={onHandleCloseDelayedDelivery}>

                        <Grid container item justifyContent="center" sx={{ fontSize: "20px", direction: "rtl", paddingRight:"15px" }}>
                            {`לקוח יקר,`}<br /><br />
                            {`החבילה שלך הועברה לחברת התעופה לצורך הטסתה לארץ.`}<br />
                            {`עקב המלחמה, חברות תעופה מבטלות טיסות רבות ללא התראה מוקדמת, ולכן החבילה שלך עדיין לא עלתה על טיסה.`}<br />
                            {`אל חשש. אנו מטפלים במשלוח ונדאג להשלים את תהליך המכס עוד לפני הגעתה לארץ, כך שעם הגעתה ארצה, החבילה תשוחרר.`}<br />
                            {`אנו מתנצלים על אי הנוחות ומזכירים שאנו כאן כדי לענות על כל שאלה. `}<br />
                        </Grid>
                        <Grid item container justifyContent="flex-end" style={{marginTop:"20px;"} }>
                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {
                                onHandleCloseDelayedDelivery();
                            }}>{ `המשך`}</Button>
                        </Grid>

                    </Dialog>
                )}

                {openInfoModal && (
                    <Dialog maxWidth='sm' direction={ 'rtl'} open={openInfoModal} title={`פרטי חבילה`} onHandleCancel={onHandleCancelInfoModal}>
                        <Grid container item justifyContent="flex-start">
                            <Grid container item justifyContent="flex-start" sx={{ fontSize: "20px",direction:"rtl" }} >

                                {shipmentInfo.params?.ext && <Grid container item justifyContent={"flex-start"} lg={12}>
                                    <Button variant='link' sx={{ fontSize:"22px",m: 2, textDecoration:"underline" ,borderRadius: "25px", padding: "3px" }} onClick={() => {
                                        //setIsProfile(true)
                                        if (parcelImage != "") {
                                            setParcelImage("");
                                        }
                                        else if (shipmentInfo.params?.ext == '.pdf') {
                                            downloadByUrl("/images/parcel/parcel_" + shipmentInfo.id + shipmentInfo.params?.ext, "parcelImage" + shipmentInfo.params?.ext)
                                        } else {
                                            //setIsLoaded(true);
                                            var url = "/shipping/downloadParcelImage/" + shipmentInfo.hawb;
                                            fetch(url)
                                                .then(response =>
                                                    response.blob()
                                                )
                                                .then(response => {
                                                    const blob = new Blob([response], { type: 'image/png' });
                                                    const downloadUrl = window.URL.createObjectURL(blob);
                                                    //setIsLoaded(false);
                                                    setParcelImage(downloadUrl);
                                                })
                                        }
                                    }} >תמונה</Button>
                                </Grid>}
                                {parcelImage!="" && <Grid container item lg={12} style={{ maxHeight: "250px" }}><img src={parcelImage} height="100%" /></Grid>}
                            </Grid>
                            <Grid container item flexWrap="nowrap" justifyContent="center" sx={{ fontSize: "20px", direction: "rtl" }} >
                                <Grid container item justifyContent={"flex-start"} lg={4}>
                                    מספר מעקב
                                </Grid>
                                <Grid container item justifyContent={"flex-start"} lg={8}>
                                    : {shipmentInfo?.parentId != 0 ? shipmentInfo?.params?.trackingNum : shipmentInfo?.hawb}
                                </Grid>
                            </Grid>
                            {/*<Grid container item justifyContent="center" sx={{ fontSize: "20px", direction: "rtl" }} >
                                <Grid container item justifyContent={"flex-start"} lg={4}>
                                    משקל לחיוב
                                </Grid>
                                <Grid container item justifyContent={"flex-start"} lg={8}>
                                    : {getChargeableWeight()} ק״ג
                                </Grid>
                            </Grid>*/}
                            <Grid container item flexWrap="nowrap" justifyContent="center" sx={{ fontSize: "20px", direction: "rtl" }} >
                                <Grid container item justifyContent={"flex-start"} lg={4}>
                                    { `משקל פיזי`}
                                </Grid>
                                <Grid container item justifyContent={"flex-start"} lg={8}>
                                    : {Number(shipmentInfo?.params?.userPaymentData?.weight).toFixed(2)} ק״ג
                                </Grid>
                            </Grid>
                            <Grid container item flexWrap="nowrap" justifyContent="center" sx={{ fontSize: "20px", direction: "rtl" }} >
                                <Grid container item justifyContent={"flex-start"} lg={4}>
                                    {`משקל נפחי`}
                                </Grid>
                                <Grid container item justifyContent={"flex-start"} lg={8}>
                                    : {Number(getChargeableWeight1()).toFixed(2)} ק״ג
                                </Grid>
                            </Grid>
                            <Grid container item flexWrap="nowrap" justifyContent="center" sx={{ fontSize: "20px", direction: "rtl" }} >
                                <Grid container item justifyContent={"flex-start"} lg={4} >
                                    {`משקל לחיוב`}
                                </Grid>
                                {!isMobile() ? <Grid container item justifyContent={"flex-end"} lg={8} sx={{ fontSize: "20px", direction: "ltr" }}>
                                    {`הגבוה מבין השניים`} :

                                </Grid> : <Grid container item justifyContent={"flex-end"} lg={8} sx={{ fontSize: "16px", direction: "ltr" }}>
                                        <span style={{ marginTop: "5px" }}>{` הגבוה מבין השניים `}</span>&nbsp;  <span style={{ fontSize: "20px" }}>{` : ` }</span>

                                </Grid>}
                            </Grid>
                            <Grid container item flexWrap="nowrap" justifyContent="center" sx={{ fontSize: "20px", direction: "rtl" }} >
                                <Grid container item justifyContent={"flex-start"} lg={4} >
                                    {`מידות ס״מ`}<br />{ `גובה x אורך x רוחב`}
                                </Grid>
                                <Grid container item justifyContent={"flex-end"} lg={8} sx={{ fontSize: "20px", direction: "ltr" }}>
                                    <br/> {shipmentInfo?.params?.userPaymentData?.height} x {shipmentInfo?.params?.userPaymentData?.length} x {shipmentInfo?.params?.userPaymentData?.width} :

                                </Grid>
                            </Grid>
                            {false && <Grid container item justifyContent="center" sx={{ fontSize: "20px", direction: "rtl" }} >
                                <Grid container item justifyContent={"flex-start"} lg={4} >
                                    {`קופון`} 
                                </Grid>
                                <Grid container item justifyContent={"flex-end"} lg={4} sx={{ fontSize: "20px", direction: "ltr" }}>
                                    
                                    ({shipmentInfo?.params?.couponText})

                                </Grid>
                                <Grid container item justifyContent={"flex-end"} lg={4} sx={{ fontSize: "20px", direction: "ltr" }}>
                                    
                                    {shipmentInfo?.params?.couponDeduction}

                                </Grid>
                            </Grid>}
                            {/*{shipmentInfo.params.repackCosts && <Grid item container justifyContent={"flex-end"} style={{ fontSize: "20px", direction: "rtl", textAlign:"right" }}>
                                <Grid item container lg={!isMobile() ? 4 : 12} > Repack Cost:</Grid>
                                <Grid item container lg={8}> $ {getTotalRepackCost(shipmentInfo.params.repackCosts)}</Grid>
                            </Grid>}*/}
                            {shipmentInfo.consolidationStatus == 3 &&
                                <Grid container item lg={12} xs={12} justifyContent="flex-end" alignContent="flex-end" sx={{ mt: 5, fontSize: "20px", direction: "rtl" }}>
                                    
                                    <Grid container item justifyContent={"flex-start"} lg={4} >
                                        {`איחוד חבילות `} 
                                    </Grid>
                                    <Grid container item justifyContent={"flex-start"} lg={8} sx={{ fontSize: "20px", direction: "rtl" }}>
                                        {shipmentInfo?.params?.notes.split("pack:")[1].replaceAll("," , " ")}

                                    </Grid>
                                </Grid>
                            }

                            <Grid container item justifyContent="center" sx={{ fontSize: "20px",mt:3 }} >
                                <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {
                                    //setIsProfile(true)
                                    setParcelImage("");
                                    onHandleCancelInfoModal();
                                    }} >OK</Button>
                            </Grid>
                        </Grid>
                    </Dialog>
                )}

                {openConsolidateConfirm && (
                    <Dialog maxWidth='sm' direction={'rtl'} open={openConsolidateConfirm} title={`איחוד חבילות`} onHandleCancel={onHandleCancelConsolidateConfirm}>
                        <Grid container item justifyContent="center">
                            
                            <Grid container item justifyContent="center" style={{ direction: "rtl", marginTop:"20px", fontSize:"1.25rem" }}>
                                { `האם להמשיך בתהליך האיחוד ?`}
                            </Grid>

                            <Grid container item justifyContent="space-around" sx={{ fontSize: "20px", mt: 1 }} >
                                <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {

                                    var ids = [];
                                    for (var i = 0; i < selectedConsGroup.parcels.length; i++) {
                                        if (selectedConsGroup.parcels[i].selected) {
                                            ids.push(selectedConsGroup.parcels[i].id)
                                        }
                                    }
                                    dispatch(setIsShippingLoading(true))
                                    onHandleCancelConsolidateConfirm()
                                    onHandleCancelConsolidateModal();
                                    consolidateParcels(getCookie("SESSION_ID"), ids, selectedConsGroup.freeText, function (data) {
                                        //console.log(data)
                                        //dispatch(setIsShippingLoading(false))
                                        onHandleCancelConsolidateConfirm()
                                        onHandleCancelConsolidateModal();
                                        if (data?.misc?.CODE == 1) {
                                            loadParcels()
                                            dispatch(snackbarToggle({ type: 'success', message: "בקשתך בטיפול ותענה בהקדם" }));
                                        } else {
                                            dispatch(setIsShippingLoading(false))
                                            dispatch(snackbarToggle({ type: 'error', message: "הערות ללקוח (255 תווים)" }));
                                        }
                                    })
                                }} >אישור</Button>
                                <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {
                                    //setIsProfile(true)
                                    onHandleCancelConsolidateConfirm();
                                }} >ביטול</Button>
                            </Grid>
                        </Grid>
                    </Dialog>
                )}

                {openConsolidateModal && (
                    <Dialog maxWidth='md' direction={'rtl'} open={openConsolidateModal} title={ `איחוד חבילות`} onHandleCancel={onHandleCancelConsolidateModal}>
                        <Grid container item justifyContent="flex-end">
                            <Grid container item justifyContent="flex-end" sx={{ border: "1px solid black", mb: 4, borderRadius:"10px",padding:"5px" }}>
                               
                                <Grid container item justifyContent="flex-end">
                                <Typography className='dir-rtl' >
                                        <b>{`לביצוע איחוד חבילות.`}</b>
                                        <ul style={{ listStyleType: "number", marginRight: "20px", marginBlockStart: "0", paddingInlineStart: "0", marginBlockEnd: "0" }}>
                                            <li>{`שרות זה מתאפשר לאחר קליטת החבילות במרכזים הלוגיסטיים שלנו, רק עבור חבילות שלא שולמו עדיין.`}</li>
                                            <li>{ `לא ניתן לפצל חבילות בחזרה לאחר איחודן פיזית.`}</li>
                                            <li>{ `לאחר ביצוע האיחוד לא יהיה ניתן להוסיף חבילה נוספת אנא בדקו שכל החבילות שברצונכם לאחד כבר נקלטו במחסן.`}</li>
                                            <li>{ `יש לסמן את החבילות אשר ברצונך לאחד ולאחר מכן לאשר את האיחוד.`}</li>
                                            <li>{ `בסיום התהליך תשלח הודעה על יצירת חבילה חדשה המורכבת מהחבילות שאוחדו.`}</li>
                                            <li>{ `בשל מגבלות משקל וגודל , ישנם מקרים בהם צוות המחסן לא יוכל לבצע איחוד חבילות.`}</li>
                                            <li>{`להסבר ולפרטים נוספים לגבי כדאיות האיחוד `} <a style={{ color: "#d70007" }} target="_blank" href="https://redboxparcel.com/blog?p=package-consolidation">{ `לחץ כאן.`}</a></li>
                                        </ul>
                                    </Typography>
                                </Grid>
                            </Grid>
                            {consolidationParcelsGroup.map((cParcelGroup, groupIndex) => (<Grid key={cParcelGroup.group} container item justifyContent="center">
                                
                                <Grid container item justifyContent="center" sx={{ border: "1px solid black", borderRadius: "10px", mb:4}}>
                                    <Grid container item justifyContent="center" lg={11} >
                                        <Button variant='outlined' sx={{ mt: -2, borderRadius: "5px", borderColor: "#d70007", color: "#d70007", padding: "3px", background: "white !important" }} onClick={() => {

                                    
                                        }} >
                                            <b> {allWh?.find(u => u.id === cParcelGroup.group)?.name}</b> 
                                        </Button>
                                    </Grid>


                                    <Grid container item justifyContent="space-between" lg={11} flexWrap="nowrap" sx={{mt:2}}>
                                        <Grid container item justifyContent="flex-end" sm={4}><div className="full_width_container bold_text rtl_text smallx_text">מספר מעקב</div> </Grid>
                                        <Grid container item justifyContent="flex-end" sm={2}><div className="full_width_container bold_text rtl_text smallx_text">חנות</div> </Grid>
                                        <Grid container item justifyContent="flex-end" sm={2}><div className="full_width_container bold_text rtl_text smallx_text"> תכולת החבילה   </div></Grid>
                                        <Grid container item justifyContent="flex-end" sm={2} ><div className="full_width_container bold_text rtl_text smallx_text">ספק </div>  </Grid>
                                    </Grid>


                                    {cParcelGroup?.parcels?.map((parcel, index) => (<Grid container item justifyContent="space-between" lg={11} flexWrap="nowrap">
                                        
                                        <Grid container item justifyContent="flex-end" sm={4}>{parcel.params?.trackingNum} </Grid>
                                        <Grid container item justifyContent="flex-end" sm={2}>{parcel.hawb} </Grid>
                                        <Grid container item justifyContent="flex-end" sm={2}>{parcel.shippingDesc} </Grid>
                                        <Grid container item justifyContent="flex-end" sm={2} ><div style={{maxWidth:"75%",textAlign:"right"}}>{parcel.params?.sender?.senderName}, {parcel.params?.sender?.senderCountry}</div> </Grid>
                                        <Grid container item justifyContent="flex-end" sm={1.5} style={{ marginTop: "-9px" }} alignContent="flex-start">
                                            <Checkbox title={dissableConsolidation(parcel, allWh?.find(u => u.id === cParcelGroup.group))?"Parcel is not elegible for consolidation as dimensions are not in the limit.":"" } disabled={dissableConsolidation(parcel, allWh?.find(u => u.id === cParcelGroup.group))} checked={parcel.selected} onChange={event => {
                                                var tCGroup = JSON.parse(JSON.stringify([...consolidationParcelsGroup]))
                                                var parcels = JSON.parse(JSON.stringify([...tCGroup[groupIndex].parcels]))
                                                for (var i = 0; i < parcels.length; i++) {
                                                    if (index == i) {
                                                        parcels[i].selected = event.target.checked;
                                                    }
                                                }
                                                tCGroup[groupIndex].parcels = parcels
                                                setConsolidationParcelsGroup(tCGroup)
                                                //event.target.checked
                                            }} />
                                        </Grid>
                                    </Grid>))}
                                    <Grid container item justifyContent="flex-end" sx={{ fontSize: "20px", mb: 1, mt: 1 }} lg={11}>
                                        <Grid container item justifyContent="flex-end" sx={{ fontSize: "20px", mb: 1, mt: 1 ,mr:1}} lg={ 11}>

                                        <MultilineField
                                                className='multilineWidth_70 text-field'
                                            lg={ 8}
                                                rows={2}
                                                value={cParcelGroup.freeText}
                                                onChange={event => {
                                                    var tCGroup = JSON.parse(JSON.stringify([...consolidationParcelsGroup]))
                                                    tCGroup[groupIndex].freeText = event.target.value;
                                                    setConsolidationParcelsGroup(tCGroup)
                                                }}
                                                label= 'הערות לצוות המחסן (באנגלית בלבד)'
                                            dir={'rtl'}
                                        />
                                        </Grid>


                                        {<Grid container direction={'rtl'} item flexDirection="row-reverse" justifyContent="centert" sx={{ padding: "10px", fontSize: "20px", textAlign: "right", marginTop: "20px", marginBottom: "20px" }}>
                                            <Grid container direction={'rtl'} item flexDirection="row-reverse" justifyContent="flex-start" sx={{ fontSize: "20px", textAlign: "right" }} flexWrap="nowrap">
                                                {!freeRepack && <><input onChange={() => setFreeRepack1(false)} type="radio" defaultChecked value="MALE" name={"" + groupIndex} /> <p style={{ margin: "0", marginRight: "10px" }}>{`איחוד חבילות מאותו ספק    –  ללא תוספת תשלום`}</p></>}
                                                {freeRepack && <><input onChange={() => setFreeRepack1(false)} type="radio" value="MALE" name={"" + groupIndex} /> <p style={{ margin: "0", marginRight: "10px" }}>{`איחוד חבילות מאותו ספק    –  ללא תוספת תשלום`}</p></>}
                                            </Grid>
                                            <Grid container direction={'rtl'} item flexDirection="row-reverse" alignItems="flex-start" justifyContent="flex-start" sx={{ fontSize: "20px", textAlign: "right" }} flexWrap="nowrap">
                                                {freeRepack && <><input style={{ marginTop: "10px" }} onChange={() => setFreeRepack1(true)} type="radio" defaultChecked value="FEMALE" name={"" + groupIndex} /> <p style={{ margin: "0", marginRight: "10px" }}>{`איחוד חבילות מספקים שונים -  כרוך בתשלום של 3$ עבור כל חבילה הנכללת באיחוד`}</p></>}
                                                {!freeRepack && <><input style={{ marginTop: "10px" }} onChange={() => setFreeRepack1(true)} type="radio" value="FEMALE" name={"" + groupIndex} /> <p style={{ margin: "0", marginRight: "10px" }}>{`איחוד חבילות מספקים שונים -  כרוך בתשלום של 3$ עבור כל חבילה הנכללת באיחוד`}</p></>}
                                            </Grid>
                                        </Grid>}

                                        <Grid container direction={'rtl'} item justifyContent="center" sx={{ fontSize: "20px", textAlign: "right" }}>
                                            { "?"} { `האם לאחד לך את החבילות שבחרת `}
                                        </Grid>

                                        <Grid container item justifyContent="center" sx={{ fontSize: "20px" }}>
                                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                                onHandleCancelConsolidateModal();
                                            }}>{`לא`}</Button>
                                            <Button disabled={!isValidForConsolidation(cParcelGroup)} variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                                //setSelectedConsGroup(cParcelGroup)
                                                onHandleCancelConsolidateModal();
                                                //setOpenConsolidateConfirm(true);

                                                var ids = [];
                                                for (var i = 0; i < cParcelGroup.parcels.length; i++) {
                                                    if (cParcelGroup.parcels[i].selected) {
                                                        ids.push(cParcelGroup.parcels[i].id)
                                                    }
                                                }
                                                dispatch(setIsShippingLoading(true))
                                                onHandleCancelConsolidateConfirm()
                                                onHandleCancelConsolidateModal();
                                                consolidateParcels(getCookie("SESSION_ID"), ids, cParcelGroup.freeText, !freeRepack, function (data) {
                                                    //console.log(data)
                                                    //dispatch(setIsShippingLoading(false))
                                                    //onHandleCancelConsolidateConfirm()
                                                    onHandleCancelConsolidateModal();
                                                    if (data?.misc?.CODE == 1) {
                                                        loadParcels()
                                                        dispatch(snackbarToggle({ type: 'success', message: "בקשתך בטיפול ותענה בהקדם" }));
                                                    } else {
                                                        dispatch(setIsShippingLoading(false))
                                                        dispatch(snackbarToggle({ type: 'error', message: "הערות ללקוח (255 תווים)" }));
                                                    }
                                                })
                                            }}>{` כן`}</Button>
                                        </Grid>
                                        {/*<Grid container item alignItems="center" justifyContent="center" sx={{ fontSize: "20px", mb: 1, mt: 0 }} lg={12}>

                                            <Button disabled={!isValidForConsolidation(cParcelGroup)} variant='contained' sx={{ borderRadius: "25px", background: "#d70007", padding: "5px 25px" }} onClick={() => {
                                                setSelectedConsGroup(cParcelGroup)
                                                onHandleCancelConsolidateModal();
                                                setOpenConsolidateConfirm(true);
                                            }} >איחוד חבילות</Button>
                                            
                                        </Grid>*/}
                                    </Grid>
                               </Grid>
                            </Grid>))}
                            
                        </Grid>
                        {/*<Grid container item justifyContent="center" sx={{ fontSize: "20px", mt: 3 }} >
                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", padding: "3px" }} onClick={() => {

                                onHandleCancelConsolidateModal();
                            }} >OK</Button>
                        </Grid>*/}
                    </Dialog>
                )}
                {openBuyMeModal && (
                    <Dialog maxWidth='md' direction={'rtl'} open={openBuyMeModal} title={'Buy-Me'} onHandleCancel={onHandleCancelBuyMeModal}>
                        <Grid container item>
                            The personal property service allows you to shop abroad without leaving home, buy the brands you want at huge discounts abroad and enjoy products that cannot be found in Israel.
                        </Grid>
                        <Grid container md={ 12} sx={{mt:5,mb:5}} justifyContent="center">
                            <Grid container item justifyContent="center">
                                <Grid container item lg={8} justifyContent="flex-end" alignContent="flex-start" sx={{ fontSize: "14px",marginRight:2 }} >
                                    <b>מאיפה תרצה לקנות</b>
                                </Grid>
                                <Grid container item lg={8} justifyContent="flex-end" alignContent="flex-start" sx={{ fontSize: "14px" }} >
                                    <SelectField
                                        required
                                        value={selectedBuyMeShopId}
                                        onChange={event => {
                                            setSelectedBuyMeShopId(event.target.value);                                       
                                        }}
                                        label=''
                                        options={buyMeShops}
                                        islabelAnimate={false}
                                        isPrefix
                                        dir="rtl"
                                        isNumeric={true}
                                        sx={{ m: 0, mr: "10px", height: "30px" }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container md={12} className='' justifyContent="center" sx={{ mt: 2 }}>
                                <Grid container item sm={8} justifyContent="flex-end" alignContent="flex-start" sx={{ fontSize: "14px", padding: 2 }} >
                                    <b>פרטי המוצר</b>
                                </Grid>
                                <Grid item container  lg={8}  >
                                    <Grid container item lg={12}>
                                    <TextField
                                        className='text-field'
                                        value={buyMeInfo.link}
                                        onChange={event => {
                                            setBuyMeInfo(prev => ({ ...prev, link: event.target.value }))
                                        }}
                                        label='לינק למוצר'
                                        islabelAnimate={false}
                                        isPrefix
                                        parentClassName=''
                                        sx={{width:"100%",  margin: "0 5px 5px 0" }}
                                        />
                                    </Grid>
                                    <Grid container item lg={6}>
                                        <TextField
                                            className='text-field'
                                            required
                                            value={buyMeInfo.size}
                                            onChange={event => {
                                                setBuyMeInfo(prev => ({ ...prev, size: event.target.value }))
                                            }}
                                            label="מידה (אם קיימת )"
                                            islabelAnimate={false}
                                            isPrefix
                                            parentClassName=''
                                            sx={{ margin: "0 5px 5px 0" }}
                                            />
                                    </Grid>
                                    <Grid container item lg={6}>
                                        <TextField
                                            className='text-field'
                                            required
                                            value={buyMeInfo.color}
                                            onChange={event => {
                                                setBuyMeInfo(prev => ({ ...prev, color: event.target.value }))
                                            }}
                                            label="צבע ( אם קיים)"
                                            islabelAnimate={false}
                                            isPrefix
                                            parentClassName=''
                                            sx={{ margin: "0 5px 5px 0" }}
                                        />
                                    </Grid>

                                    <Grid container item lg={12} justifyContent="flex-end" alignContent="center">
                                        <Grid container item lg={6} justifyContent="flex-end" alignContent="center" sx={{ mb: 3 }}>
                                            <SelectField
                                                required
                                                value={selectedCur}
                                                onChange={event => {
                                                    setSelectedCur(event.target.value);
                                                }}
                                                label=''
                                                options={currencies}
                                                islabelAnimate={false}
                                                isPrefix
                                                dir="rtl"
                                                lg={6}
                                                isNumeric={true}
                                                sx={{ m: 0, mr: "10px", height: "30px", width: "100%" }}
                                            />
                                        </Grid>
                                        <Grid container item lg={6}>
                                            <TextField
                                                className='text-field'
                                                required
                                                value={buyMeInfo.price}
                                                onChange={event => {
                                                    setBuyMeInfo(prev => ({ ...prev, price: event.target.value }))
                                                }}
                                                label="מחיר"
                                                islabelAnimate={false}
                                                isPrefix
                                                parentClassName=''
                                                sx={{ margin: "0 5px 5px 0" }}
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                    

                                    
                                   
                                    <Grid container item lg={6}>
                                        <SelectField
                                            required
                                            value={numberOfUnitId}
                                            onChange={event => {
                                                setnumberOfUnitId(event.target.value);
                                            }}
                                            label=''
                                            options={numberOfUnits}
                                            islabelAnimate={false}
                                            isPrefix
                                            dir="rtl"
                                            lg={ 12}
                                            isNumeric={true}
                                            sx={{ m: 0, mr: "10px", height: "30px",width:"100%" }}
                                        />
                                    </Grid>
                                    <Grid container item lg={6} justifyContent="flex-start" alignContent="center">
                                        <b style={{ marginRight: "12px"}}>מספר יחידות</b>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid container item justifyContent="center" sx={{mt:2}}>
                                
                                <Grid container item lg={8} justifyContent="flex-end" alignContent="flex-start" sx={{ fontSize: "14px" }} >
                                    <MultilineField
                                        className='multilineWidth_70 text-field'
                                        lg={12}
                                        rows={2}
                                        value={buyMeInfo.freeText}
                                        onChange={event => {
                                            setBuyMeInfo(prev => ({ ...prev, freeText: event.target.value }))
                                        }}
                                        label="הערות"
                                        dir={'rtl'}
                                    />
                                    <Button onClick={() => {
                                        var cur = currencies?.find(u => u.id === selectedCur)
                                        var wh = buyMeShops?.find(u => u.id === selectedBuyMeShopId)
                                        var numUnit = numberOfUnits?.find(u => u.id === numberOfUnitId)
                                        var object = {
                                            url: buyMeInfo.link,
                                            color: buyMeInfo.color,
                                            size: buyMeInfo.size,
                                            price: buyMeInfo.price,
                                            freeText: buyMeInfo.freeText,
                                            whName: wh?.name,
                                            whId: wh?.id,
                                            numUnits: numUnit?.name,
                                            currency: selectedCur,
                                            otherInfo: {
                                                currencyObj: cur
                                            }
                                            ,
                                        }
                                        addBuyMeRequest(userInfo.session, object, function (resp) {
                                            if (resp.misc.CODE == 1) {
                                                getBuyMeRequestsItems();
                                                dispatch(snackbarToggle({ type: 'success', message: "Buy-Me request added succesfully" }));
                                                onHandleCancelBuyMeModal();
                                            } else {
                                                dispatch(snackbarToggle({ type: 'error', message: resp.message }));

                                            }
                                        })

                                    }}> קבל הצעה</Button>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent="flex-start">
                            <b>Important Information</b>
                            <ul>
                                <li>After sending the request, we will locate the product(s) for you, calculate the tax costs (if applicable) and the shipping costs to Israel</li>
                                <li>The price offer will be sent to you by email or text message, depending on your choice, within 2 business days</li>
                                <li>The quote is valid for 48 hours from the moment it is delivered</li>
                                <li>The shipping time of the product(s) to USSHOPS logistics centers abroad is the responsibility of the store where the order is placed</li>
                                <li>Transactions over NIS 300 can be divided into up to 36 equal payments</li>
                                <li>After placing an order we will purchase the product(s) for you. The products will be delivered to you within about 15 business days</li>
                                <li>The purchase is subject to the terms of use</li>
                            </ul>
                        </Grid>
                    </Dialog>
                )}
                <MetaTags id="metaAccounts">
                    <title>Redbox Accounts </title>
                    <meta http-equiv="Cache-Control" content="no-cache, must-revalidate" />
                    <meta http-equiv="Pragma" content="no-cache" />
                    <meta http-equiv="Expires" content="0" />
                    <meta id="meta-description" name="Accounts" content="איזור אישי" />
                    <meta id="meta-description-title" property="og:title" content="Redbox parcel" />
                </MetaTags>
                <Box component={'section'} className="page-title dark_image_filter" >
                <Box className="title-section">
                    <Box className="container">
                        <Typography component={'h1'} className="page-main-title center_text rtl_text bold_text">איזור אישי</Typography>
                            <Typography component={'h2'} className="page-main-title center_text rtl_text bold_text cursor_pointer" style={{textDecoration:"underline",fontSize:"20px"}} onClick={() => {
                                setIsProfile(true)
                            }}>הגדרות</Typography>
                    </Box>
                </Box>
            </Box>

            <main id="main-content">
                <div className="full_width">
                    <Box className="container">
                        <Grid container className="pad_60_0 popular-section" style={{ justifyContent: "center" }}>
                                {couponList.filter((c) => { if (!c.personal ) { return c} }).map((coupon, index) => (
                                <> {
                                        index < 3 && coupon.banner && <Grid item md={2.8} sm={5.5} xs={12} onClick={() => {
                                        copyToClipboard1(coupon.couponId, function (data) {
                                            dispatch(snackbarToggle({ type: 'success', message: "הקופון הועתק בהצלחה" }));
                                            window.setTimeout(function () { dispatch(snackbarToggle(false)); }, autoCloseSnackbar);
                                        });
                                        }} className="cursor_pointer shadow_box small_container relative_container  show_overflow" style={{ minHeight: "140px", margin: '0 0.8% 20px' }}>
                                            <img alt={coupon.params?.type} src={`data:${coupon.params?.type};base64,${coupon.banner}`} className="absolute_container" style={{ height: "100%", top: 0, left: 0, width: "100%", borderRadius: '20px' }} />
                                        {index == 0 && false && <img src={woman_jumping} className="absolute_container" style={{ borderRadius: '20px', height: "17vw", top: "-1vw", left: "-9vw", width: "auto", zIndex: 1 }} />}

                                    </Grid>
                                }
                                </>
                            ))

                            }
                                <Grid item md={2.8} sm={5.5} xs={12} className=" shadow_box small_container white_container cursor_pointer" style={{ minHeight: "140px", margin: '0 0.8% 20px', flexDirection: 'column' }} onClick={() => { setIsAddAddress(true); }}>
                                <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                    <div className="col_flex">
                                            <div className="rtl_text darkgrey_text bold_text midx_text">הכתובות שלי בארץ</div>
                                            {<div className="row_flex row_space_center col_space_center">
                                                <div style={{ height:"25px" }} />
                                                {/*<img src={flag_tr_small} style={{ width: "23px", height: "15px", margin: "5px" }} />
                                            <img src={flag_po_small} style={{ width: "23px", height: "15px", margin: "5px" }} />
                                            <img src={flag_eu_small} style={{ width: "23px", height: "15px", margin: "5px" }} />
                                            <img src={flag_us_small} style={{ width: "23px", height: "15px", margin: "5px", marginRight: 0 }} />
*/}
                                            </div>}
                                    </div>
                                    <div className="col_flex">
                                        <img src={address} style={{ width: "40px", height: "40px", margin: "10px" }} />
                                    </div>
                                </div>
                                    <div className="full_width_container smallx_text darkgrey_text rtl_text top_space_20" style={{ marginRight: "10px" }}>הכתובות למשלוח</div>
                                    <div className="full_width_container midx_text red_text rtl_text bold_text" style={{ marginRight: "10px" }}>הצג כתובות {'>'}</div>
                            </Grid>
                            <Grid item md={2.8} sm={5.5} xs={12} className=" shadow_box small_container white_container cursor_pointer" style={{ minHeight: "140px", margin: '0 0.8% 20px', flexDirection: 'column' }} onClick={() => { setActiveScreenInreg('PersonalData'); setIsRegister(true) }}>
                                <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                    <div className="col_flex">
                                            <div className="rtl_text darkgrey_text bold_text midx_text">הכתובות שלי בחו״ל</div>
                                        <div className="row_flex row_space_center col_space_center">
                                            {/*<img src={flag_tr_small} style={{ width: "23px", height: "15px", margin: "5px" }} />
                                            <img src={flag_po_small} style={{ width: "23px", height: "15px", margin: "5px" }} />
                                            */}
                                             <img src={flag_eu_small} style={{ width: "23px", height: "15px", margin: "5px" }} />
                                            <img src={flag_us_small} style={{ width: "23px", height: "15px", margin: "5px", marginRight: 0 }} />
                                        </div>
                                    </div>
                                    <div className="col_flex">
                                        <img src={address} style={{ width: "40px", height: "40px", margin: "10px" }} />
                                    </div>
                                </div>
                                    <div className="full_width_container smallx_text darkgrey_text rtl_text top_space_20" style={{ marginRight: "10px" }}>יצרנו עבורך כתובות לכל היעדים שלנו</div>
                                    <div className="full_width_container midx_text red_text rtl_text bold_text" style={{ marginRight: "10px" }}>הצג כתובות {'>'}</div>
                            </Grid>
                            {false && <div className="col-md-3 small_container white_container" style={{ height: "10vw" }}>
                                <div className="full_width_container row_flex top_space_20" style={{ justifyContent: "flex-end" }}>
                                    <div className="col_flex">
                                        <div className="darkgrey_text bold_text midx_text right_text">Super Shopper</div>
                                        <div className="row_flex white_container" style={{ justifyContent: "flex-start", width: "100%", height: "0.3vw", background: "#f0f0f2", boxShadow: "none !important" }}>
                                            <div className="darkgreen_button" style={{ width: "40%", height: "0.3vw" }}></div>
                                        </div>
                                        <div className="darkgrey_text bold_text rtl_text smallx_text">עוד 218 נק' ל- Mega shopper</div>
                                    </div>
                                    <div className="col_flex">
                                        <img src={trophy} style={{ width: "40px", height: "40px", margin: "10px" }} />
                                    </div>
                                </div>
                                <div className="full_width_container smallx_text darkgrey_text rtl_text top_space_20" style={{ marginRight: "10px" }}>מתקדמים בדרגות ומרוויחים יותר קשבק על כל קנייה</div>
                                <div className="full_width_container smallx_text darkgrey_text rtl_text bold_text" style={{ marginRight: "10px" }}>קראו עוד {'>'}</div>
                            </div>
                            }
                            {false && <div className="col-md-3 small_container white_container" style={{ height: "10vw" }}>
                                <div className="full_width_container row_flex top_space_20" style={{ justifyContent: "flex-end" }}>
                                    <div className="col_flex">
                                        <div className="darkgrey_text bold_text midx_text rtl_text right_text"> ה-Cash Back שלי</div>
                                        <div className="darkgrey_text bold_text midx_text right_text">12$</div>
                                    </div>
                                    <div className="col_flex">
                                        <img src={cashback} style={{ width: "40px", height: "40px", margin: "10px" }} />
                                    </div>
                                </div>
                                <div className="full_width_container smallx_text darkgrey_text rtl_text top_space_20" style={{ marginRight: "10px" }}>צוברים 0.25 $ קשבק על כל משלוח מעל 30 $</div>
                                <div className="full_width_container smallx_text darkgrey_text rtl_text bold_text" style={{ marginRight: "10px" }}>קראו עוד {'>'}</div>
                            </div>}
                        </Grid>
                        </Box>
                        <Grid items container lg={12} justifyContent="center">
                            <Grid items container justifyContent="center" lg={5.5} md={11} sx={11} style={{ textAlign: "center", margin: "10px", padding: "20px", direction: "rtl", color: "#d70007", fontSize: "20px", fontWeight: "bold", border: "1px solid #d70007", borderRadius: "5xp" }}>
                                {`שימו לב, אנחנו מתרחבים והמחסן שלנו בארה"ב עבר לכתובת חדשה!`}<br></br>
                                {`לפני המשלוח הבא שלכם, אנא עדכנו את הכתובת החדשה באתרים מהם אתם מזמינים.`}<br></br>
                                {`תודה רבה! צוות רדבוקס פרסל`}
                            </Grid>
                        </Grid>
                        {!isMobile()&&<div className="full_width top_space_40 row_flex row_space_center col_space_center">

                           
                            {<Grid container item sm={3} justifyContent="flex-start" >
                                {(userInfo?.user?.token == 'RBP00103' || userInfo?.user?.token == 'RBP00102')&&<Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                    onHandleOpenBuyMeModal()
                                    setBuyMeInfo({})
                                }}>   Buy-Me</Button>}
                                <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                    onHandleOpenConsolidateModal()
                                }}>איחוד חבילות</Button>
                                {(userInfo?.user?.token == 'RBP00103' || userInfo?.user?.token == 'RBP00102')&&<Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                    onHandleOpenRepackModal()
                                }}>Repack</Button>}
                            </Grid>}
                            <span className="rtl_text mid_text  cursor_pointer" onClick={() => {
                                setIsHistory(true)
                                setTabIndex(2)
                            }} style={{ fontWeight: tabIndex == 2 ? 'bold' : '' }}>&nbsp; &nbsp; &nbsp; היסטוריית הזמנות ({getHistoryCount()}) &nbsp;&nbsp;&nbsp;</span>
                            <span onClick={() => {
                                setIsHistory(false)
                                setTabIndex(1)
                            }} className="rtl_text mid_text  cursor_pointer" style={{ fontWeight: tabIndex == 1 ? 'bold' : '' }}>&nbsp;&nbsp;&nbsp;הזמנות פעילות (<span id="shipping_items"> {shipping_list.length - getHistoryCount()}</span>)&nbsp;&nbsp;&nbsp;</span>
                            {(userInfo?.user?.token == 'RBP00103' || userInfo?.user?.token == 'RBP00102') && <span onClick={() => {
                                setIsHistory(false)
                                setTabIndex(3)
                            }} className="rtl_text mid_text  cursor_pointer" style={{ fontWeight: tabIndex == 3 ? 'bold' : '' }}>&nbsp;&nbsp;&nbsp;Buy-Me (<span id="shipping_items">{buyMeRequestsItems?.length}</span>)&nbsp;&nbsp;&nbsp;</span>
/**/}
                        </div>
                        }
                        {isMobile() && <Grid container item lg={12} justifyContent="center" >
                            {<Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                onHandleOpenConsolidateModal()
                            }}>איחוד חבילות</Button>}
                        </Grid>}
                        {isMobile() && (userInfo?.user?.token == 'RBP00103' || userInfo?.user?.token == 'RBP00102') && <Grid container item lg={12} justifyContent="center" >
                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                onHandleOpenBuyMeModal()
                            }}>   Buy-Me</Button>
                            <Button variant='contained' sx={{ m: 2, borderRadius: "25px", background: "#d70007", paddingLeft: "20px", paddingRight: "20px" }} onClick={() => {
                                onHandleOpenRepackModal()
                            }}>Repack</Button>
                        </Grid>}
                        {isMobile() && <div style={{ flexWrap:"wrap" }} className="full_width top_space_40 row_flex row_space_center col_space_center">
                            
                            <span className="rtl_text mid_text  cursor_pointer" onClick={() => {
                                setIsHistory(false)
                                setTabIndex(2)
                            }} style={{ fontWeight: tabIndex==2 ? 'bold' : '' }}>&nbsp; &nbsp; &nbsp; היסטוריית הזמנות ({getHistoryCount()}) &nbsp;&nbsp;&nbsp;</span>
                            <span onClick={() => {
                                setIsHistory(true)
                                setTabIndex(1)
                            }} className="rtl_text mid_text  cursor_pointer" style={{ fontWeight: tabIndex == 1 ? 'bold' : '' }}>&nbsp;&nbsp;&nbsp;הזמנות פעילות (<span id="shipping_items"> {shipping_list.length - getHistoryCount()}</span>)&nbsp;&nbsp;&nbsp;</span>
                            {(userInfo?.user?.token == 'RBP00103' || userInfo?.user?.token == 'RBP00102') &&<span onClick={() => {
                                setIsHistory(false)
                                setTabIndex(3)
                            }} className="rtl_text mid_text  cursor_pointer" style={{ fontWeight: tabIndex==3 ? 'bold' : '' }}>&nbsp;&nbsp;&nbsp;Buy-Me(<span id="shipping_items">{buyMeRequestsItems?.length}</span>)&nbsp;&nbsp;&nbsp;</span>
/**/}
                        </div>
                        }
                    <div className="container shipping_transactions top_space_40 bot_space_40 row_flex row_space_center col_space_center">
                        <Box className="width_83 shipping_transaction_table" style={{ alignItems: 'flex-start' }}>
                                {tabIndex==1 &&<table id="shipping_transaction_table" className="shipping_transaction_table">
                                    <tbody>
                                        {isShippingLoading && <tr key={uuidv4()} className="white_container" style={{ padding: "10px" }}>
                                            <td style={{ border: "none" }}>
                                                <Loader isFullDisplay />
                                            </td>
                                        </tr>
                                        }
                                        {shipping_list?.length == 0 && <tr key={uuidv4()} className="white_container" style={{ padding: "10px" }}>
                                            <td colSpan={5} style={{ border: "none", textAlign: "center" }}>

                                                <Grid container lg={12} justifyContent="center" flexDirection="column" alignContent="center" alignItems="center">
                                                    <Grid item lg={9}> התחל את חווית הקניה</Grid>
                                                    <Grid item lg={3}><Box className="store_list_button_account" onClick={() => { navigate('/store', { state: { id: 1, name: 'store_list' } }) }}>{`לרשימת כל החנויות`}</Box></Grid>
                                                </Grid>
                                            </td>
                                        </tr>
                                        }
                                        {shipping_list?.length > 0 && !isHistory &&
                                            shipping_list?.map((sh, shIndex) => (
                                                <>{sh.shippingStatus != 6 && !sh.status &&
                                                    <tr key={sh.id || uuidv4()} className="white_container" style={{ padding: "10px" }} title={(sh.params && sh.params.shippingStatus?.status_json) ? sh.params?.shippingStatus?.status_json?.STATUS_2 : ''} onClick={(event) => { setShowExtraServices(event, -1); }}>
                                                        <td style={{ border: "none", minWidth: "300px" }} id="account_info_button">
                                                            <Grid item container flexWrap="nowrap" alignItems="center">
                                                                {(!sh.repackStatus && !sh.rescanStatus && !sh.consolidationStatus && (sh.shippingStatus == 1 || sh.shippingStatus == 2)) && <Grid container item sm={6}>
                                                                    <div class="dropdown" style={{position:"relative"}}>
                                                                        <div className="redoutline_button red_text col_flex col_space_center row_space_center cursor_pointer" onClick={(e) => {
                                                                            if (showExtraServices != shIndex)
                                                                                setShowExtraServices(e, shIndex)
                                                                            else
                                                                                setShowExtraServices(e, -1)
                                                                        }}><p>{ `שירותים נוספים`}</p></div>
                                                                        {showExtraServices == shIndex && <Grid item container style={{ zIndex: "1000", position: "absolute", background: "white", minWidth: "100px", overflow: "auto", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)" }}>
                                                                            <p onClick={() => { onSelectService(1, sh) }} style={{ cursor: "pointer", margin: "0", width: "100%", marginTop: "5px", marginBottom: "5px", textAlign: "center" }}>{ `צמצום נפח`}</p>
                                                                            <p onClick={() => { onSelectService(2, sh) }} style={{ cursor: "pointer", margin: "0", width: "100%", marginTop: "5px", marginBottom: "5px", textAlign: "center" }}>{ `צילום תכולה`}</p>
                                                                        </Grid>}
                                                                    </div>
                                                            </Grid>}
                                                            <Grid item container sm={6}>
                                                            {((!sh.consolidationStatus || sh.consolidationStatus == 3) && (sh.shippingStatus <= 2 || sh.shippingStatus == 104 || sh.shippingStatus == 201)) ? <div onClick={() => {
                                                                        if ((sh.repackStatus == 0 || sh.repackStatus == 104 || sh.repackStatus == null) && sh.shippingStatus != 201 && (sh.rescanStatus == 0 || sh.rescanStatus == null || sh.rescanStatus == 104)) {
                                                                    if (!sh.status) {
                                                                        if (!userInfo?.user?.setting?.profileId) {
                                                                            onHandleOpenModal()
                                                                        } else {
                                                                            dispatch(setSelectedShipping(sh));
                                                                            setIsPersonal(true);
                                                                        }
                                                                    }
                                                                }
                                                                if (sh.shippingStatus == 201) {
                                                                    //onHandleOpenCCDialog();
                                                                }
                                                                    }} style={{ color: ((sh.repackStatus == 0 || sh.repackStatus == 104 || sh.repackStatus == null) && (sh.rescanStatus == 0 || sh.rescanStatus == null || sh.rescanStatus == 104)) ? "white" : "grey" }} className="red_button white_text col_flex col_space_center row_space_center cursor_pointer">{!sh.status ? <p>{ `לתשלום`}</p> : <p>&nbsp;  </p>}</div>

                                                                : <div className="redoutline_button red_text col_flex col_space_center row_space_center cursor_pointer" onClick={() => {
                                                                    var sh_t = JSON.parse(JSON.stringify(sh))
                                                                    if (!sh_t.params.statusLog) {
                                                                        var sl = []
                                                                        for(var i =9; i>0;i--) {
                                                                            var m = {};
                                                                            m.id = i;
                                                                            m.DATE = null;
                                                                            m.DESC = null;
                                                                            sl.push(m);
		                                                                }
                                                                        sh_t.params.statusLog = sl;
                                                                    }
                                                                    var status = getStatus2Id(sh_t)
                                                                    var inStatus = getStatusByLogId(sh_t);
                                                                    if (sh_t.params.manifestId && (getStatusById(sh_t, 3) || getStatusById(sh_t, 4))) {
                                                                        dispatch(setIsShippingLoading(true))
                                                                        getETAStatus(sh_t.params.manifestId, function (data) {
                                                                            var shipmentETAList = [];
                                                                            var tempShipmentETAList = [];
                                                                            if (data.message == "SUCCESS") {
                                                                                shipmentETAList = data.data.etaLogs;
                                                                                //shipmentETAUpdate = (formatDate(new Date(data.update))).replace(/,/g, '');
                                                                            }
                                                                            for (var i = 0; i < shipmentETAList.length; i++) {
                                                                                var tempLog = {}
                                                                                tempLog.DATE = shipmentETAList[i].updateTime;
                                                                                tempLog.DESC = `נחיתה צפויה בנתב״ג` + `: ` + getDateFormat(shipmentETAList[i].arrivalDateTime);
                                                                                tempShipmentETAList.push(tempLog);
                                                                            }
                                                                            var t1 = 0;
                                                                            var t1Id = 0;
                                                                            for (var i = 0; i < sh_t?.params?.statusLog.length; i++) {
                                                                                var tempLog = {}
                                                                                tempLog.DATE = (new Date(sh_t?.params?.statusLog[i].DATE)).getTime();
                                                                                tempLog.DESC = sh_t?.params?.statusLog[i].DESC;
                                                                                tempLog.id = sh_t?.params?.statusLog[i].id;
                                                                                tempShipmentETAList.push(tempLog);
                                                                            }
                                                                            tempShipmentETAList.sort(function (a, b) {
                                                                                return b.DATE - a.DATE;
                                                                            });
                                                                            //shipmentETAList.sort(function (a, b) { a.updateTime < b.updateTime ? -1 : a.updateTime > b.updateTime?1:0 })
                                                                            var s1 = JSON.parse(JSON.stringify(sh_t));
                                                                            s1.params.statusLog = tempShipmentETAList;
                                                                            //s1.shipmentETAList = tempShipmentETAList;
                                                                            dispatch(setIsShippingLoading(false))
                                                                            for (var i = 0; i < s1?.params?.statusLog?.length; i++) {
                                                                                
                                                                            }
                                                                            dispatch(setSelectedShipping(s1));
                                                                            setIsPersonal(true);
                                                                            if (status.DATE && inStatus) {
                                                                                //if (status.DATE) {
                                                                                var desc = status.DESC.split(" ");
                                                                                var dateETA = new Date(desc[desc.length - 1].split(".")[0]);
                                                                                var dToday = new Date().getTime();

                                                                                //if (dToday > dateETA.getTime()) {// payment date + 14 days (old)
                                                                                //if (dToday > status.DATE + 21 * 24 * 3600 * 1000) {
                                                                                //    setOpenDelayedDelivery(true)
                                                                               // }
                                                                            }
                                                                        })
                                                                    }
                                                                    else {
                                                                        dispatch(setIsShippingLoading(false))
                                                                        dispatch(setSelectedShipping(sh_t));
                                                                        setIsPersonal(true);
                                                                        if (status?.DATE && inStatus) {
                                                                            //if (status.DATE) {
                                                                            var desc = status.DESC.split(" ");
                                                                            var dateETA = new Date(desc[desc.length - 1].split(".")[0]);
                                                                            var dToday = new Date().getTime();

                                                                            //if (dToday > dateETA.getTime()) {// payment date + 14 days (Old)
                                                                            //if (dToday > status.DATE + 21 * 24 * 3600 * 1000) {
                                                                            //    setOpenDelayedDelivery(true)
                                                                            //}
                                                                        }
                                                                    }
                                                                    
                                                                        }}><p>{ `למעקב`}</p> </div>}
                                                            </Grid>
                                                            </Grid>
                                                        </td>
                                                        {/*<td style={{ border: "none" }} id="account_service_button">
                                                            {(userInfo?.user?.token == 'RBP00103' || userInfo?.user?.token == 'RBP00102') &&
                                                            <SelectField
                                                                required
                                                                value={repackService}
                                                                onChange={event => {
                                                                    //alert(event.target.value);
                                                                    if (event.target.value == 2) {
                                                                        openRepackageDlg(sh);
                                                                    }
                                                                }}
                                                                label=''
                                                                options={[{ id: 1, name: "Services" }, { id: 2, name: "Repack Service" }]}
                                                                islabelAnimate={false}
                                                                isNumeric={true}
                                                                disabled={isServiceNotAvailable(sh)}
                                                                isPrefix
                                                                dir="rtl"
                                                                sx={{ m: 0, mr: "10px", height: "30px" }}
                                                            />}
                                                        </td>*/}
                                                    
                                                        <td style={{ border: "none" }} id="account_status1_1">
                                                            <Grid container item justifyContent="center" alignItems="center" flexDirection="column">
                                                                <Grid container item>
                                                                    {(!sh.consolidationStatus && !sh.repackStatus) &&

                                                                        <div className="">
                                                                            {sh.params?.isInsurence && <span className="rtl_text smallx_text">ביטוח מוצר</span>}
                                                                            {sh.params?.isInsurence && <span><img src={correct} style={{ width: "14px", height: "14px", marginLeft: "5px" }} /></span>}
                                                                            {!sh.params?.isInsurence && false && <CloseIcon style={{ width: "15px", height: "15px", border: "1px solid black", borderRadius: '25px' }} />}

                                                                        </div>
                                                                    }
                                                                    {(sh.consolidationStatus == 1) &&
                                                                        <div className="">
                                                                            בקשה לאיחוד
                                                                        </div>
                                                                    }
                                                                    {(sh.consolidationStatus == 3) &&

                                                                        <div className="">
                                                                            חבילה מאוחדת
                                                                        </div>
                                                                    }
                                                                    {(sh.repackStatus == 101) &&

                                                                        <div className="">
                                                                            בקשה לצמצום נפח
                                                                        </div>
                                                                    }
                                                                    {repackCompleted(sh) &&

                                                                        <div className="">
                                                                            צמצום נפח
                                                                        </div>
                                                                    }
                                                                    {(sh.rescanStatus == 101) &&

                                                                        <div className="">
                                                                            {`בקשה לצילום תכולה`}
                                                                        </div>
                                                                    }
                                                                    {rescanCompleted(sh) &&
                                                                        <Grid item container className="">
                                                                            {`צילום תכולה`} <span><div onClick={() => {
                                                                                downloadScanImage(sh.hawb, function () { })
                                                                            }} style={{ marginLeft: "5px", backgroundImage: `url(${file})`, cursor: 'pointer', backgroundRepeat: "no-repeat", width: '16px', height: '20px', }}></div></span>
                                                                        </Grid>
                                                                    }
                                                                    {(sh.repackStatus == 103 || sh.repackStatus == 102) &&

                                                                        <div className="">
                                                                            Repacking...
                                                                        </div>
                                                                    }
                                                                    {(sh.scanStatus == 101) &&

                                                                        <div className="">
                                                                            Scanning Started
                                                                        </div>
                                                                    }
                                                                </Grid>
                                                                
                                                            </Grid>
                                                        </td>
                                                    <td style={{ border: "none" }} id="account_hawb">
                                                        <div className="col_flex col_space_center row_space_center">
                                                            <div className="full_width_container bold_text rtl_text smallx_text">מספר מעקב</div>
                                                            <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                                                <span className="smallx_text account_hawb_content" id="account_hawb_content">
                                                                    {sh.hawb}
                                                                </span>
                                                                <span style={{ cursor: "pointer" }} onClick={() => {
                                                                    copyToClipboard1(sh.hawb, function (data) {
                                                                        dispatch(snackbarToggle({ type: 'success', message: data }));
                                                                    });
                                                                }}><img src={copy} style={{ width: "12px", height: "14px", marginLeft: "5px" }} /></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ border: "none" }} id="account_price">
                                                        <div className="col_flex col_space_center row_space_center">
                                                            <div className="full_width_container bold_text rtl_text smallx_text">קטגוריית מכס:</div>
                                                            <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                                                <span className="rtl_text smallx_text account_dev_inst" id="account_dev_inst">
                                                                    {sh.params?.majorCategory || "-"}
                                                                </span>
                                                                <span><img src={price_tag} style={{ width: "14px", height: "15px", marginLeft: "5px" }} /></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ border: "none" }} id="account_description">
                                                            <div className="col_flex col_space_center row_space_center">
                                                                <Grid container item sm={6}>
                                                                    <div class="dropdown" style={{ position: "relative" }}>
                                                                        <div style={{border:"2px solid #d70007", fontWeight:"bold"}} className="redoutline_button red_text col_flex col_space_center row_space_center cursor_pointer" onClick={(e) => {
                                                                            onHandleOpenInfoModal(sh)
                                                                        }}><p>{`לפרטים/תמונה`}</p></div>
                                                                    </div>
                                                                </Grid>
                                                                {/*<div className="full_width_container bold_text rtl_text smallx_text">
                                                                    <span className="cursor_pointer" onClick={() => {
                                                                    onHandleOpenInfoModal(sh);
                                                                    }}>
                                                                        <img src={info_26} style={{ width: "15px", height: "15px", marginLeft: "5px" }} />
                                                                    </span>
                                                                    { `לפרטים/תמונה`} </div>
                                                            <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                                                <span className="rtl_text smallx_text account_description_text" id="account_description_text">
                                                                        {sh.parentId == 0 ? (sh.params?.vParent?.vendorName ? sh.params?.vParent?.vendorName : sh.shippingDesc) : sh.shippingDesc}
                                                                </span>
                                                                <span><img src={packagePng} style={{ width: "15px", height: "15px", marginLeft: "5px" }} /></span>
                                                            </div>*/}
                                                        </div>
                                                    </td>
                                                    {!sh.shippingStatus && <td style={{ border: "none" }} id="account_package_status_recieved">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                <div className="rtl_text bold_text smallx_text">החבילה נקלטה</div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_recieved_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={package_recieved} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>
                                                    }

                                                    {(sh.shippingStatus == 1 || sh.shippingStatus == 100) && <td style={{ border: "none" }} id="account_package_status_recieved">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                    <div className="rtl_text bold_text smallx_text">{sh.parentId != 0 ? `החבילה נקלטה` : `ההזמנה נקלטה`}</div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_recieved_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={package_recieved} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>
                                                    }
                                                    {sh.shippingStatus == 2 && <td style={{ border: "none" }} id="account_package_status_payment_pending">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                    <div className="rtl_text bold_text smallx_text">{sh.parentId != 0 ? `ממתין לתשלום` : `החבילה  נקלטה`}</div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_payment_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={payment_pending} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>}
                                                    {sh.shippingStatus == 3 && <td style={{ border: "none" }} id="account_package_status_shipping_started">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                                <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                    <div className="rtl_text bold_text smallx_text">{getStatusId(sh) == 2 ? 'החבילה בטיפול' :'בדרך לארץ'} </div>
                                                                <div className="right_text">
                                                                        <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_shipping_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                                </div>
                                                                <img src={getStatusId(sh) == 2 ? package_recieved : shipment_started} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>
                                                    }
                                                    {(sh.shippingStatus == 4 || sh.shippingStatus == 41) && <td style={{ border: "none" }} id="account_package_status_shipping_started">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                <div className="rtl_text bold_text smallx_text">בדרך לארץ</div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_shipping_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={shipment_started} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>
                                                    }
                                                    {sh.shippingStatus == 5 && <td style={{ border: "none" }} id="account_package_status_shipping_started">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                    <div className="rtl_text bold_text smallx_text">{getStatusDescId(sh) ? getStatusDescId(sh) : ((sh.params && sh.params.shippingStatus?.status_json) ? sh.params?.shippingStatus?.status_json?.STATUS_2 : 'בדרך לארץ')} </div>
                                                                    {/*<div className="rtl_text bold_text smallx_text">{(sh.params && sh.params.shippingStatus?.status_json) ? sh.params?.shippingStatus?.status_json?.STATUS_2 : 'בדרך לארץ'} </div>*/}
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_shipping_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                                </div>
                                                                
                                                                <img src={getStatusId(sh) == 4 ? package_recieved : delivery_started } style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>
                                                        }
                                                        {sh.shippingStatus == 104 && <td style={{ border: "none" }} id="account_package_status_delivery_started">
                                                            <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                                <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                    <div className="rtl_text bold_text smallx_text">{(sh.params && sh.params.shippingStatus?.status_json) ? sh.params?.shippingStatus?.status_json?.STATUS_2 : 'החבילה בטיפול'}  </div>
                                                                    <div className="right_text">
                                                                        <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                        <span className="smallx_text" id="account_package_delivery_date">
                                                                            {getDateFormat(sh.date)}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <img src={package_recieved} style={{ width: "33px", height: "33px" }} />
                                                            </div>
                                                        </td>}
                                                        {sh.params && sh.shippingStatus != 104 && sh.shippingStatus != 100 && sh.shippingStatus != 41 && sh.shippingStatus != 1 && sh.shippingStatus != 6 && sh.shippingStatus != 2 && sh.shippingStatus != 3 && sh.shippingStatus != 4 && sh.shippingStatus != 5 && <td style={{ border: "none" }} id="account_package_status_delivery_started">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                    <div className="rtl_text bold_text smallx_text">{(sh.params && sh.params.shippingStatus?.status_json) ? sh.params?.shippingStatus?.status_json?.STATUS_2 : 'בדרך אליך'}  </div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_delivery_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={delivery_started} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>}
                                                    {sh.shippingStatus == 6 && <td style={{ border: "none" }} id="account_package_status_shipping_started">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                <div className="rtl_text bold_text smallx_text">החבילה נמסרה</div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_shipping_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/*<img src={shipment_started} style={{ width: "33px", height: "33px" }} />*/}
                                                        </div>
                                                    </td>
                                                    }
                                                    {/*{sh.params && sh.params.shippingStatus &&sh.shippingStatus != 1 && sh.shippingStatus != 6 && sh.shippingStatus != 2 && sh.shippingStatus != 3 && sh.shippingStatus != 4&& sh.shippingStatus != 5 && <td style={{ border: "none" }} id="account_package_status_delivery_started">
                                                    <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                        <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                            <div className="rtl_text bold_text smallx_text">{sh.params.shippingStatus.StatusText}</div>
                                                            <div className="right_text">
                                                                <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                <span className="smallx_text" id="account_package_delivery_date">
                                                                    {getDateFormat(sh.date)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <img src={delivery_started} style={{ width: "33px", height: "33px" }} />
                                                    </div>
                                                </td>}*/}
                                                    {<td style={{ border: "none" }} id="account_package_target_1">
                                                        <div className="col_flex col_space_center row_space_center">
                                                            <div className="full_width_container bold_text smallx_text right_text">{`ספק`}</div>
                                                            <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                                                <span className="rtl_text smallx_text">{sh.params?.sender?.senderName}, {sh.params?.sender?.senderCountry}</span>
                                                            </div>
                                                        </div>
                                                    </td>}
                                                    {/*<td style={{ border: "none" }} id="account_package_target_2">
                                                    <div className="col_flex col_space_center row_space_center">
                                                        {sh.params?.sender?.senderName}
                                                    </div>
                                                </td>
                                                */}

                                                </tr>}
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </table>}
                                {tabIndex == 2 && <table id="shipping_transaction_table" className="shipping_transaction_table">
                                    <tbody>
                                        {isShippingLoading && <tr key={uuidv4()} className="white_container" style={{ padding: "10px" }}>
                                            <td style={{ border: "none" }}>
                                                <Loader isFullDisplay />
                                            </td>
                                        </tr>
                                        }
                                        {shipping_list?.length == 0 && <tr key={uuidv4()} className="white_container" style={{ padding: "10px" }}>
                                            <td colSpan={5} style={{ border: "none", textAlign: "center" }}>

                                                <Grid container lg={12} justifyContent="center" flexDirection="column" alignContent="center" alignItems="center">
                                                    <Grid item lg={9}> התחל את חווית הקניה</Grid>
                                                    <Grid item lg={3}><Box className="store_list_button_account" onClick={() => { navigate('/store', { state: { id: 1, name: 'store_list' } }) }}>{`לרשימת כל החנויות`}</Box></Grid>
                                                </Grid>
                                            </td>
                                        </tr>
                                        }
                                        {shipping_list?.length > 0 && isHistory &&
                                            shipping_list?.map(sh => (
                                                <> 
                                                   
                                                    {(sh.shippingStatus == 6 || sh.status) && <tr key={sh.id || uuidv4()} className="white_container" style={{ padding: "10px" }} title={(sh.params && sh.params.shippingStatus?.status_json) ? sh.params?.shippingStatus?.status_json?.STATUS_2 : ''}>



                                                    {sh.shippingStatus > 2 ? (<td style={{ border: "none" }} id="account_info_button">
                                                            {!sh.status ?<div className="redoutline_button red_text col_flex col_space_center row_space_center cursor_pointer" onClick={() => {
                                                                if (!sh.status) {
                                                                    dispatch(setSelectedShipping(sh));
                                                                    setIsPersonal(true);
                                                                }
                                                            }}> {!sh.status ? <p>{ `למעקב`} </p> : <p>&nbsp; </p>} </div> : <>

                                                                
                                                                    {sh.shippingStatus ==104&&<div onClick={() => {
                                                                        var parent = sh.trackListOfConsolidation?.length > 0 ? shipping_list?.find(u => u.id === sh.trackListOfConsolidation[0]) : null;
                                                                        if (parent) {
                                                                            dispatch(setSelectedShipping(parent));
                                                                            setIsPersonal(true);
                                                                        }
                                                                    }} className="redoutline_button red_text col_flex col_space_center row_space_center cursor_pointer"> <p> {sh.trackListOfConsolidation?.length > 0 ? shipping_list?.find(u => u.id === sh.trackListOfConsolidation[0])?.hawb : ""}</p></div>}

                                                            </>}
                                                    </td>)
                                                        :
                                                        (<td style={{ border: "none", padding: "10px" }} id="account_pay_button">
                                                                {!sh.status ? <div onClick={() => {
                                                                    if ((sh.repackStatus == 0 || sh.repackStatus == 104 || sh.repackStatus == null) && (sh.rescanStatus == 0 || sh.rescanStatus == 104 || sh.rescanStatus == null)) {
                                                                        if (!sh.status) {
                                                                            if (!userInfo?.user?.setting?.profileId) {
                                                                                onHandleOpenModal()
                                                                            } else {
                                                                                dispatch(setSelectedShipping(sh));
                                                                                setIsPersonal(true);
                                                                            }
                                                                        }
                                                                    }
                                                                }} className="red_button white_text col_flex col_space_center row_space_center cursor_pointer">{!sh.status ? <p> { `לתשלום`}</p> : <p>&nbsp;  </p>}</div>:<></>}
                                                        </td>)
                                                    }
                                                    <td style={{ border: "none" }} id="account_status1_1">
                                                            {sh.shippingStatus != 104 && <div className="">

                                                                {sh.params?.isInsurence && <span className="rtl_text smallx_text">ביטוח מוצר</span>}
                                                                {sh.params?.isInsurence && <span><img src={correct} style={{ width: "14px", height: "14px", marginLeft: "5px" }} /></span>}
                                                                {!sh.params?.isInsurence && false && <CloseIcon style={{ width: "15px", height: "15px", border: "1px solid black", borderRadius: '25px' }} />}

                                                            </div>}
                                                            {sh.shippingStatus == 104 && <div className="">

                                                                <span className="rtl_text smallx_text">- חבילה מאוחדת ב</span>
                                                               
                                                            </div>}
                                                    </td>
                                                    {/*{sh.shippingStatus == 1 ? (<td style={{ border: "none" }} id="account_status2_1">
                                                    <div className="col_flex col_space_center row_space_center">
                                                        <div className="full_width_container bold_text rtl_text smallx_text">שירותים נוספים</div>
                                                        <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <span className="rtl_text smallx_text">אריזה מחדש</span>
                                                            <span><img src={correct} style={{ width: "14px", height: "14px", marginLeft: "5px" }} /></span>
                                                        </div>
                                                    </div>
                                                </td>) :
                                                    (<td style={{ border: "none" }} id="account_status2_2">
                                                        <div className="col_flex col_space_center row_space_center">
                                                            <div className="full_width_container bold_text rtl_text smallx_text">שירותים נוספים</div>
                                                            <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                                                <span className="rtl_text smallx_text" style={{ color: "#b9b9b9" }}>אריזה מחדש</span>
                                                                <span><img src={correct_dis} style={{ width: "14px", height: "14px", marginLeft: "5px" }} /></span>
                                                            </div>
                                                        </div>
                                                    </td>)
                                                }*/}
                                                    <td style={{ border: "none" }} id="account_hawb">
                                                        <div className="col_flex col_space_center row_space_center">
                                                            <div className="full_width_container bold_text rtl_text smallx_text">מספר מעקב</div>
                                                            <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                                                <span className="smallx_text account_hawb_content" id="account_hawb_content">
                                                                    {sh.hawb}
                                                                </span>
                                                                <span style={{ cursor: "pointer" }} onClick={() => {
                                                                    copyToClipboard1(sh.hawb, function (data) {
                                                                        dispatch(snackbarToggle({ type: 'success', message: data }));
                                                                    });
                                                                }}><img src={copy} style={{ width: "12px", height: "14px", marginLeft: "5px" }} /></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ border: "none" }} id="account_price">
                                                        <div className="col_flex col_space_center row_space_center">
                                                            <div className="full_width_container bold_text rtl_text smallx_text">קטגוריית מכס:</div>
                                                            <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                                                <span className="rtl_text smallx_text account_dev_inst" id="account_dev_inst">
                                                                    {sh.params?.majorCategory || "-"}
                                                                </span>
                                                                <span><img src={price_tag} style={{ width: "14px", height: "15px", marginLeft: "5px" }} /></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ border: "none" }} id="account_description">
                                                        <div className="col_flex col_space_center row_space_center">
                                                                <div className="full_width_container bold_text rtl_text smallx_text">
                                                                    <span class="cursor_pointer" onClick={() => {
                                                                        onHandleOpenInfoModal(sh);
                                                                    }}>
                                                                        <img src={info_26} style={{ width: "15px", height: "15px", marginLeft: "5px" }} />
                                                                    </span>
                                                                    פרטי חבילה/תמונה
                                                                </div>
                                                            <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                                                <span className="rtl_text smallx_text account_description_text" id="account_description_text">
                                                                        {sh.parentId == 0 ? (sh.params?.vParent?.vendorName ? sh.params?.vParent?.vendorName : sh.shippingDesc) : sh.shippingDesc}
                                                                </span>
                                                                <span><img src={packagePng} style={{ width: "15px", height: "15px", marginLeft: "5px" }} /></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {!sh.shippingStatus && <td style={{ border: "none" }} id="account_package_status_recieved">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                <div className="rtl_text bold_text smallx_text">החבילה נקלטה</div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_recieved_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={package_recieved} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>
                                                    }

                                                        {(sh.shippingStatus == 1 || sh.shippingStatus == 100) && <td style={{ border: "none" }} id="account_package_status_recieved">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                <div className="rtl_text bold_text smallx_text">החבילה נקלטה</div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_recieved_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={package_recieved} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>
                                                    }
                                                    {sh.shippingStatus == 2 && <td style={{ border: "none" }} id="account_package_status_payment_pending">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                <div className="rtl_text bold_text smallx_text">ממתין לתשלום</div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_payment_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={payment_pending} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>}
                                                        {sh.shippingStatus == 3 && <td style={{ border: "none" }} id="account_package_status_shipping_started">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                    {/*<div className="rtl_text bold_text smallx_text">בדרך לארץ</div>*/}
                                                                    <div className="rtl_text bold_text smallx_text">{'החבילה בטיפול'}</div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_shipping_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={shipment_started} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>
                                                    }
                                                    {(sh.shippingStatus == 4 || sh.shippingStatus == 41) && <td style={{ border: "none" }} id="account_package_status_shipping_started">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                    <div className="rtl_text bold_text smallx_text">בדרך לארץ</div>
                                                                {/*<div className="rtl_text bold_text smallx_text">בדרך לארץ</div>*/}
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_shipping_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={shipment_started} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>
                                                    }
                                                    {sh.shippingStatus == 5 && <td style={{ border: "none" }} id="account_package_status_shipping_started">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                    <div className="rtl_text bold_text smallx_text">{(sh.params && sh.params.shippingStatus?.status_json) ? sh.params?.shippingStatus?.status_json?.STATUS_2 : 'בדרך לארץ'} </div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_shipping_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={shipment_started} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>
                                                    }
                                                    {sh.params && sh.shippingStatus != 100 && sh.shippingStatus != 41 && sh.shippingStatus != 1 && sh.shippingStatus != 6 && sh.shippingStatus != 2 && sh.shippingStatus != 3 && sh.shippingStatus != 4 && sh.shippingStatus != 5 && <td style={{ border: "none" }} id="account_package_status_delivery_started">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                    <div className="rtl_text bold_text smallx_text">{(sh.params && sh.params.shippingStatus?.status_json) ? sh.params?.shippingStatus?.status_json?.STATUS_2 : 'בדרך אליך'}  </div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_delivery_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img src={delivery_started} style={{ width: "33px", height: "33px" }} />
                                                        </div>
                                                    </td>}
                                                    {sh.shippingStatus == 6 && <td style={{ border: "none" }} id="account_package_status_shipping_started">
                                                        <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                            <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                                <div className="rtl_text bold_text smallx_text">החבילה נמסרה</div>
                                                                <div className="right_text">
                                                                    <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                    <span className="smallx_text" id="account_package_shipping_date">
                                                                        {getDateFormat(sh.date)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/*<img src={shipment_started} style={{ width: "33px", height: "33px" }} />*/}
                                                        </div>
                                                    </td>
                                                    }
                                                    {/*{sh.params && sh.params.shippingStatus &&sh.shippingStatus != 1 && sh.shippingStatus != 6 && sh.shippingStatus != 2 && sh.shippingStatus != 3 && sh.shippingStatus != 4&& sh.shippingStatus != 5 && <td style={{ border: "none" }} id="account_package_status_delivery_started">
                                                    <div className="row_flex" style={{ justifyContent: "flex-end" }}>
                                                        <div className="col_flex" style={{ justifyContent: "flex-end", marginRight: "10px" }}>
                                                            <div className="rtl_text bold_text smallx_text">{sh.params.shippingStatus.StatusText}</div>
                                                            <div className="right_text">
                                                                <span className="right_text rtl_text smallx_text">תאריך עדכון: </span>
                                                                <span className="smallx_text" id="account_package_delivery_date">
                                                                    {getDateFormat(sh.date)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <img src={delivery_started} style={{ width: "33px", height: "33px" }} />
                                                    </div>
                                                </td>}*/}
                                                    {<td style={{ border: "none" }} id="account_package_target_1">
                                                        <div className="col_flex col_space_center row_space_center">
                                                            <div className="full_width_container bold_text smallx_text right_text">{`ספק`}</div>
                                                            <div className="full_width_container row_flex" style={{ justifyContent: "flex-end" }}>
                                                                <span className="rtl_text smallx_text">{sh.params?.sender?.senderName}, {sh.params?.sender?.senderCountry}</span>
                                                            </div>
                                                        </div>
                                                    </td>}
                                                    {/*<td style={{ border: "none" }} id="account_package_target_2">
                                                    <div className="col_flex col_space_center row_space_center">
                                                        {sh.params?.sender?.senderName}
                                                    </div>
                                                </td>
                                                */}

                                                </tr>
                                                    }</>
                                            ))
                                        }
                                    </tbody>
                                </table>}
                                {((userInfo?.user?.token == 'RBP00103' || userInfo?.user?.token == 'RBP00102') && (tabIndex == 3)) && <>
                                    


                                    <TableContainer sx={{ maxHeight: 800 }}>
                                        <Table className='parcel-table' stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant='h7'>
                                                            <b>Warehouse</b> 
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant='h7'>
                                                            <b>Link</b>  
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant='h7'>
                                                            <b>Color</b>   
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Typography variant='h7'>
                                                            <b>Size</b>
                                                        </Typography>
                                                    </TableCell>
                                                    
                                                    <TableCell>
                                                        <Typography variant='h7'>
                                                            <b>Price per product</b>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant='h7'>
                                                            <b>Quantity</b>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant='h7'>
                                                            <b>Payable</b>
                                                        </Typography>
                                                    </TableCell>



                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    buyMeRequestsItems?.map((request, index) => (
                                                        <TableRow style={{ background: (index % 2) == 0 ? '#ebeaea' : 'white' }}>

                                                            <TableCell style={{ padding: "10px" }}>
                                                                <Typography variant='body1' component='span'>
                                                                    {request.whName}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell style={{ padding: "10px", overflow: "hidden", textWrap: "nowrap" }}>
                                                                <Typography variant='body1' component='span'>
                                                                    
                                                                    <a href={request.url} target="_blank">{request.url}</a>
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell style={{ padding: "10px" }}>
                                                                <Typography variant='body1' component='span'>
                                                                    {request.color}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell style={{ padding: "10px" }}>
                                                                <Typography variant='body1' component='span'>
                                                                    {request.size}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell style={{ padding: "10px" }}>
                                                                <Typography variant='body1' component='span'>
                                                                    {request.price}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell style={{ padding: "10px" }}>
                                                                <Typography variant='body1' component='span'>
                                                                    {request.numUnits}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell style={{ padding: "10px" }}>
                                                                <Typography variant='body1' component='span'>
                                                                    {request.cost ? request.cost:"-"}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell style={{ padding: "10px" }}>
                                                                <Typography variant='body1' component='span'>
                                                                    {request.status==2&& <Button variant='contained' sx={{ borderRadius: "25px", background: "#d70007", padding: "5px 25px" }} onClick={() => {
                                                                        dispatch(setSelectedShipping(request));
                                                                        setIsBuyMePayment(true)
                                                                        
                                                                    }}>תשלום</Button>}
                                                                </Typography>
                                                            </TableCell>

                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
</> }
                        </Box>
                        {isShippingLoading ?
                        <Loader  isFullDisplay /> :
                        <Grid container item sm={12} className='shipping_transaction_responsive'>
                                    
                                    {shipping_list?.length > 0 &&
                                        shipping_list?.map((sh, shIndex) => (
                                    <>{isHistory && (sh.shippingStatus != 6 && !sh.status) &&<Grid key={sh.id} container item sm={5.8} className='transaction-card'>
                                        <Grid container item xs={12} className='card-content dir-rtl'>
                                            <Grid container item xs={5} className="right-col">
                                                <img src={packagePng} style={{ width: "32px", height: "32px" }} />
                                                <Grid container item xs={12} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Typography className='text_bold'> {sh.shippingDesc}</Typography>
                                                </Grid>
                                                <Grid container className='loc'>
                                                    
                                                    <img src={LocMarker} className='marker-icon' alt="" style={{ width: "12px", height: "15px", marginLeft: "5px" }} />
                                                    <Typography >{sh.params?.sender?.senderCountry}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={7}>
                                                <Grid container item xs={12} className='upper-row'>
                                                    <Grid container className='flex-row' >
                                                        <Typography className='text_bold'>{getDescByShStatus(sh.shippingStatus)}</Typography>
                                                        <img src={payment_pending} style={{ width: "14px", height: "14px", marginRight: '5px' }} />

                                                       
                                                    </Grid>
                                                    <Typography>תאריך עדכון: {getDateFormat(sh.date)}</Typography>
                                                </Grid>
                                                <Grid container item xs={12} className='lower-row'>
                                                    <Typography className='text_bold'>
                                                        <span  className="cursor_pointer" onClick={() => {
                                                        onHandleOpenInfoModal(sh);
                                                    }}>
                                                        <img src={info_26} style={{ width: "15px", height: "15px", marginLeft: "5px" }} />
                                                        </span> {sh.hawb}</Typography>
                                                    <Grid container className='flex-row' onClick={() => {
                                                        copyToClipboard1(sh.hawb, function (data) {
                                                            dispatch(snackbarToggle({ type: 'success', message: data }));
                                                            window.setTimeout(function () { dispatch(snackbarToggle(false)); }, autoCloseSnackbar);
                                                        });
                                                    }}>
                                                        <img src={copy} style={{ width: "12px", height: "14px", marginLeft: "5px" }} />
                                                        <Typography >מספר מעקב</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12} className='lower-row'>
                                                    {(sh.consolidationStatus == 1) && <Typography className='text_bold'>בקשה לאיחוד</Typography>}
                                                    {(sh.consolidationStatus == 3) && <Typography className='text_bold'>חבילה מאוחדת</Typography>}
                                                   
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid container item xs={12} style={{ textAlign: 'center', justifyContent: 'center', marginTop: '20px' }}>
                                            {(sh.repackStatus == 101) &&

                                                <div className="">
                                                    בקשה לצמצום נפח
                                                </div>
                                            }
                                            {repackCompleted(sh) &&

                                                <div className="">
                                                    צמצום נפח
                                                </div>
                                            }
                                            {(sh.rescanStatus == 101) &&

                                                <div className="">
                                                            { `בקשה לצילום תכולה`}
                                                </div>
                                            }
                                            {rescanCompleted(sh) &&
                                                        <div className="" style={{ display: "flex"}}>
                                                            {`צילום תכולה`} <span><div onClick={() => {
                                                                downloadScanImage(sh.hawb, function () { })
                                                            }} style={{ marginLeft: "5px", backgroundImage: `url(${file})`, cursor: 'pointer', backgroundRepeat: "no-repeat", width: '16px', height: '20px', }}></div></span>
                                                </div>
                                            }
                                        </Grid>
                                        <Grid container item xs={12} style={{ textAlign: 'center', justifyContent: 'center', marginTop: '20px' }}>
                                                    {(!sh.repackStatus && !sh.rescanStatus && !sh.consolidationStatus && (sh.shippingStatus == 1 || sh.shippingStatus == 2)) && <Grid container item sm={6}>
                                                <div class="dropdown" style={{ position: "relative", width: "100%", minHeight:"40px" }}>
                                                    <div style={{minHeight:"40px"}} className="redoutline_button red_text col_flex col_space_center row_space_center cursor_pointer" onClick={(e) => {
                                                        if (showExtraServices != shIndex)
                                                            setShowExtraServices(e, shIndex)
                                                        else
                                                            setShowExtraServices(e, -1)
                                                    }}><p>{`שירותים נוספים`}</p></div>
                                                    {showExtraServices == shIndex && <Grid item container style={{ zIndex: "1000", position: "absolute", background: "white", minWidth: "100px", width:"70%", left:"13%", overflow: "auto", boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)" }}>
                                                        <p onClick={() => { onSelectService(1, sh) }} style={{ cursor: "pointer", margin: "0", width: "100%", marginTop: "5px", marginBottom: "5px", textAlign: "center" }}>{ `צמצום נפח`}</p>
                                                        <p onClick={() => { onSelectService(2, sh) }} style={{ cursor: "pointer", margin: "0", width: "100%", marginTop: "5px", marginBottom: "5px", textAlign: "center" }}>{ `צילום תכולה`}</p>
                                                    </Grid>}
                                                </div>
                                            </Grid>}

                                            {sh.shippingStatus > 2 ? <ActionButton onClick={() => {
                                                var status = getStatus2Id(sh)
                                                var inStatus = getStatusByLogId(sh);
                                                dispatch(setSelectedShipping(sh));
                                                setIsPersonal(true);
                                                if (status.DATE && inStatus) {
                                                    //if (status.DATE) {
                                                    var desc = status.DESC.split(" ");
                                                    var dateETA = new Date(desc[desc.length - 1].split(".")[0]);
                                                    var dToday = new Date().getTime();

                                                    if (dToday > dateETA.getTime()) {
                                                        setOpenDelayedDelivery(true)
                                                    }
                                                }
                                                    }} textLabel="למעקב" className='underline-btn fullwidth-btn' />
                                                        : <ActionButton onClick={() => {
                                                            if ((sh.repackStatus == 0 || sh.repackStatus == 104 || sh.repackStatus == null) &&
                                                                (sh.rescanStatus == 0 || sh.rescanStatus == 104 || sh.rescanStatus == null)) {
                                                                if (!userInfo?.user?.setting?.profileId) {
                                                                    onHandleOpenModal()
                                                                } else {
                                                                    dispatch(setSelectedShipping(sh));
                                                                    setIsPersonal(true);
                                                                }
                                                            }
                                                        }} textLabel="לתשלום" className='fullwidth-btn'  />}
                                        </Grid>
                                    </Grid>}

                                        {!isHistory && (sh.shippingStatus == 6 || sh.status)  && <Grid key={sh.id} container item sm={5.8} className='transaction-card'>
                                            <Grid container item xs={12} className='card-content dir-rtl'>
                                                <Grid container item xs={5} className="right-col">
                                                    <img src={packagePng} style={{ width: "32px", height: "32px" }} />
                                                    <Grid container item xs={12} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                        <Typography className='text_bold'> {sh.shippingDesc}</Typography>
                                                    </Grid>
                                                    <Grid container className='loc'>
                                                        <img src={LocMarker} className='marker-icon' alt="" style={{ width: "12px", height: "15px", marginLeft: "5px" }} />
                                                        <Typography >{sh.params?.sender?.senderCountry}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={7}>
                                                    <Grid container item xs={12} className='upper-row'>
                                                        <Grid container className='flex-row' >
                                                            <Typography className='text_bold'>{getDescByShStatus(sh.shippingStatus)}</Typography>
                                                            <img src={payment_pending} style={{ width: "14px", height: "14px", marginRight: '5px' }} />
                                                        </Grid>
                                                        <Typography>תאריך עדכון: {getDateFormat(sh.date)}</Typography>
                                                    </Grid>
                                                    <Grid container item xs={12} className='lower-row'>
                                                        <Typography className='text_bold'><span className="cursor_pointer" onClick={() => {
                                                            onHandleOpenInfoModal(sh);
                                                        }}>
                                                            <img src={info_26} style={{ width: "15px", height: "15px", marginLeft: "5px" }} />
                                                        </span> {sh.hawb} </Typography>
                                                        <Grid container className='flex-row' onClick={() => {
                                                            copyToClipboard1(sh.hawb, function (data) {
                                                                dispatch(snackbarToggle({ type: 'success', message: data }));
                                                                window.setTimeout(function () { dispatch(snackbarToggle(false)); }, autoCloseSnackbar);
                                                            });
                                                        }}>
                                                            <img src={copy} style={{ width: "12px", height: "14px", marginLeft: "5px" }} />
                                                            <Typography >מספר מעקב</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                                <Grid container item xs={12} style={{ textAlign: 'center', justifyContent: 'center', marginTop: '20px' }}>
                                                {sh.shippingStatus > 2 ? <ActionButton onClick={() => {
                                                    dispatch(setSelectedShipping(sh));
                                                    setIsPersonal(true);
                                                        }} textLabel="למעקב" className='underline-btn fullwidth-btn' />
                                                    : (!sh.status ? <>{<ActionButton onClick={() => {
                                                        if (sh.repackStatus == 0 || sh.repackStatus == 104 || sh.repackStatus == null) {
                                                            if (!sh.status) {
                                                                if (!userInfo?.user?.setting?.profileId) {
                                                                    onHandleOpenModal()
                                                                } else {
                                                                    dispatch(setSelectedShipping(sh));
                                                                    setIsPersonal(true);
                                                                }
                                                            }
                                                        }
                                                            }} textLabel="לתשלום" className='fullwidth-btn' />}</> : <></>)}
                                            </Grid>
                                        </Grid>}

                                    </>))}
                            
                        </Grid>}
                    </div>
                    {false && <div className="full_width top_space_80">
                        <div className="width_83 margin_left_833 rtl_text">
                            <div className="full_width_container relative_container" style={{ height: "15.2vw" }}>
                                <div className="absolute_container white_container" style={{ top: 0, left: 0, width: "55vw", height: "11vw" }}>
                                    <div className="margin_right_10 bold_text midx_text bold_text right_text top_space_40">Red Box נותנים לכם כסף!</div>
                                    <div className="margin_right_10 bold_text small_text right_text top_space_10">הזמינו חבר להרשם לשירות של Red Box ושניכם תקבלו 5$ מאיתנו למשלוח הבא!</div>
                                    <div className="margin_right_10 redoutline_button red_text bold_text center_text top_space_20" style={{ padding: "0.5vw", width: "6vw" }}>למידע נוסף</div>
                                </div>
                                <div className="absolute_container" style={{ bottom: 0, right: 0, width: "35vw", height: "10vw" }}>
                                    <img src={woman_bg} style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: "0" }} />
                                    <img src={woman_with_money} style={{ width: "auto", height: "20vw", position: "absolute", bottom: "-0.6vw", left: "27%" }} />
                                </div>
                            </div>
                        </div>
                    </div>}
                    {false && <div className="full_width top_space_40">
                        <div className="width_83 margin_left_833">
                            <div className="full_width_container relative_container" style={{ height: "15.2vw" }} >
                                <div className="absolute_container" style={{ width: "26vw", height: "11vw", bottom: 0, left: 0, zIndex: "2" }} >
                                    <img src={chrome_bg} className="absolute_container" style={{ width: "100%", height: "100%" }} />
                                    <img src={googleChromeIcon} className="absolute_container" style={{ right: "-2vw", bottom: "-0.5vw", width: "17vw", height: "17vw" }} />
                                </div>
                                <div className="absolute_container white_container" style={{ width: "64vw", height: "12vw", top: 0, right: 0, zIndex: 1 }} >
                                    <div className="rtl_text margin_right_10 mid_text top_space_20 bold_text">עדיין אין לכם את התוסף ל-Google Chrome?</div>
                                    <div className="rtl_text margin_right_10 small_text bold_text top_space_10 bot_space_10">בלעדי ל-RedboxParcel,  התוסף החכם שלנו מאפשר מילוי פרטים אוטומטי ותשלום בשקלים ללא עמלת המרה!</div>
                                    <div className="redoutline_button center_text rtl_text red_text float_right margin_right_10 bold_text top_space_10" style={{ width: "15vw" }}><p>להתקנה מחנות האפליקציות</p></div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {/*<div className="full_width">
                        <h1 className="full_width_container center_text red_text bold_text">החנויות הכי מומלצות</h1>
                        <div className="width_83 margin_left_833">
                            <div className="col-md-3 small_container">
                                <p className="top-right-corner-text">קוסמטיקה</p>
                                <div className="account_outlets_image_container_left bot_space_40">
                                    <img src={prevArrow} className="account_outlets_prev_arrow" />
                                    <img src={sephora} className="account_outlets_image_left" />
                                </div>
                            </div>
                            <div className="col-md-3 small_container">
                                <p className="top-right-corner-text">אלקטרוניקה</p>
                                <div className="account_outlets_image_container_center bot_space_40">
                                    <img src={media_markt} className="account_outlets_image_center" />
                                </div>
                            </div>
                            <div className="col-md-3 small_container">
                                <p className="top-right-corner-text">מרקט פלייס</p>
                                <div className="account_outlets_image_container_center bot_space_40">
                                    <img src={amazon} className="account_outlets_image_center" style={{ marginTop: "30px" }} />
                                </div>
                            </div>
                            <div className="col-md-3 small_container">
                                <p className="top-right-corner-text">מרקט פלייס</p>
                                <div className="account_outlets_image_container_right bot_space_40">
                                    <img src={target} className="account_outlets_image_right" />
                                    <img src={nextArrow} className="account_outlets_next_arrow" />
                                </div>
                            </div>
                        </div>
                    </div>*/}
                        {false&&<Box component={'section'} className="popular-section" style={{ paddingTop: 0 }}>
                        <Box className="container">
                            <Typography component={'h1'} className='section-title'>החנויות הפופולאריות</Typography>
                            <Grid container sx={{ justifyContent: { sm: 'space-between', xs: 'center' } }}>
                                {storeList.map((st, ind) => (<Grid container key={ind} item md={1.9} sm={3.8} xs={5.8} className="shadow_box">
                                    {/* <Grid container className='head' sx={{ flexWrap: "nowrap" }}>
                                        <Box>{st.category}</Box>
                                        <Box><img src={LocMarker} className='marker-icon' alt="" /><span>{st.country}</span></Box>
                                    </Grid> */}
                                    <Grid container className='content'>
                                        <img onClick={() => {
                                            window.open(st.link, "_blank");
                                        }} style={{ maxHeight: "50px" }} src={`/images/shops/${st.id}_logo${getExt(st.params?.fileName_logo)}`} alt='' />
                                    </Grid>
                                    <Grid container className='action'>
                                        <Button className='action-btn underline-btn' onClick={() => {
                                            setSelectedStore(st);
                                            setIsDialog(true);
                                        }}>מעבר לחנות</Button>
                                    </Grid>
                                </Grid>
                                ))}


                            </Grid>
                            <Box className="row set_mid">
                                    <Box className="store_list_button_account" onClick={() => { navigate('/store', { state: { id: 1, name: 'store_list' } }) }}>{`לרשימת כל החנויות`}</Box>
                            </Box>
                        </Box>
                    </Box>}
                    </div>
                    
            </main>
        </Paper>
        <DialogWithSideBar
                open={isDialog}
                isOutClose={true}
                onClose={() => { setIsDialog(false); setBSideWindow(false) }}
            >
                <Box container className="reg_popup_data">
                    <Box item lg={12} className="popup_tittle lg">
                        לפני שאתם עוברים לאתר
                    </Box>
                    <Box item lg={12} className="popup_tittle">
                        <img style={{ height: "30px" }} src={`/images/shops/${selectedStore.id}_logo${getExt(selectedStore.params?.fileName_logo)}`} alt='' />
                    </Box>
                    <Box item lg={12} className="popup_tittle sm">
                        טיפים להזמנה באתר
                    </Box>
                    <Grid container item lg={12} justifyContent="flex-end">
                        <ul className="store_tips">
                            {allWh?.find(u => u.id === selectedStore.params?.wId)?.params?.rightTextList?.map((t, index) => (<>{t && <li key={index}>
                                {t}
                            </li>}</>))}
                        </ul>
                    </Grid>
                   {/* <Box item lg={12} className="popup_tittle" onClick={() => {
                        setBSideWindow(true);
                    }}>

                        <img className="cursor_pointer" style={{ maxWidth: "none", height: "280px" }} src={`data:${selectedStore.params?.right_type};base64,${selectedStore.rightInfo}`} alt='' />
                    </Box>*/}
                   {/* { <Box item lg={12} className="popup_tittle" >
                        <ActionButton type={'submit'} textLabel={`איך למלא את פרטי המשלוח`} onClick={() => {
                            setBSideWindow(true);
                        }} sx={{ height: "50px !important", fontSize:"16px !important" }} />
                    </Box>} */}
                    
                    <Box item lg={12} className="popup_tittle sm anchor" 
                        style={{ position: 'relative', cursor: 'pointer'}} 
                        onClick={() => setBSideWindow(true)}>
                        <img src={store_middle_button} style={{ maxWidth: '110%', marginLeft: '-9%' }} alt={'טיפים להזמנה באתר'} />
                        <label className='btton_label'>{`טיפים להזמנה באתר`}</label>
                    </Box>

                   
                    <Grid justifyContent="center" alignItems="center" style={{ background: "#f6f6f6", borderRadius: "20px", border: "1px solid #e9e9e9", padding: '10px' }}>
                        <div style={{ fontSize: "16px !important", lineHeight: 1.13, padding: "10px" }} className="top_space_20 bot_space_10 redoutline_button red_text col_flex col_space_center row_space_center cursor_pointer bold_text" onClick={() => {

                            if (userInfo.session) {
                                setActiveScreenInreg('PersonalData'); setIsRegister(true);
                            } else {
                                setIsLogin(true)
                            }

                        }} variant={'outlined'}>
                            התחברו וקבלו את הכתובת שלכם
                        </div>
                        <Box className="bot_space_20 center_text cursor_pointer" onClick={() => {
                            if (userInfo.session) {
                                setActiveScreenInreg('PersonalData'); setIsRegister(true);
                            } else {
                                setActiveScreenInreg('Registeration'); setIsRegister(true);
                            }
                        }}>
                            <span className="">
                                עדיין לא נרשמתם? <span style={{ fontWeight: "bold" }}>הירשמו בחינם וקבלו כתובת לקניות בחו"ל &gt;</span>
                            </span>
                        </Box>
                    </Grid>
                    <Box className='form-action'>
                        <ActionButton type={'submit'} className={'submit-action'} textLabel={`${selectedStore.name} המשך לאתר`} onClick={() => {
                            window.open(selectedStore.link, "_blank")
                        }} />
                    </Box>
                </Box>
                {bSideWindow && <Box className="reg_popup_data left-side">
                    <Grid container item lg={1} justifyContent="flex-start" alignContent="flex-end" sx={{ mt: '2vh !important', fontSize: "24px", fontWeight: "bold", color: "#d70007", cursor: "pointer" }} onClick={() => { setBSideWindow(false) }}>
                        <img src={top_back} className="flag_img" style={{ width: "40px" }} />
                    </Grid>

                    <Box item lg={12} className="popup_tittle lg">
                        טיפים לרישום וקנייה באתר זה
                    </Box>
                    <Grid container item lg={12} justifyContent="flex-end" sx={{padding:"3rem !important", paddingBottom:"0 !important"}}>
                        <ul className="store_tips">
                            {selectedStore.params?.textList?.map((t, index) => (<>{t && <li key={index}>
                                {t}
                            </li>}</>))}
                        </ul>
                    </Grid>
                    <Grid container item lg={11} justifyContent="center" alignContent="center" sx={{ color: '#d70007', fontSize: "14px", fontWeight: "bold" }}>
                        <img src={`/images/shops/${selectedStore.id}_left${getExt(selectedStore.params?.fileName_left)}`} alt='' />
                    </Grid>

                </Box>}
            </DialogWithSideBar>
        </>
    );
}

export default Accounts;